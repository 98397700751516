import { Injectable } from '@angular/core';
import { DOMAINS } from '@content/domains';
import {Account, PositionsGroup} from "@models/byDateYearEndTypes";

@Injectable({
    providedIn: 'root',  
})

export class HoldingDetailURLService {

    public createURL(accountId: string, positionId: string): string {
        return `${DOMAINS.HOLDINGS}/holding-details/${accountId}?positionId=${positionId}`;
    }

    public setDetailsUrlForGroupedPositions = (accountItem: Account): PositionsGroup[] => {
        return accountItem.groupedPositions.map(group => ({
          ...group,
          positions: group.positions.map(position => ({
            ...position,
            holdingDetailsRoute: this.createURL(accountItem.accountId, position.positionId)
          })).filter(item => !item.settlementFund)
        }))
      }

}