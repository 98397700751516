import { Injectable } from '@angular/core';
import { delay, Observable, ReplaySubject, Subject, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  Account,
  AccountHeaders,
  ByDateAccountData,
  GroupedAccounts,
  PartialError,
  Positions,
  Total,
  ByDateData,
} from '@models/byDateYearEndTypes';
import { content } from '@content/content';
import { CommonService } from '@app/core/services/common/common.service';
import { LoggerService } from '@vanguard/secure-site-components-lib';
import { LoggerCode } from '@models/logger';
import { GraphQLService } from '@app/core/services/graphql/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { ByDateGQL, ByDateQuery, ByDateQueryVariables } from '@generated/graphql';
import { AssetType, AssetTypeService } from '@app/core/services/asset-grouping/asset-type.service';
import { PositionGroupingService } from '@app/core/services/asset-grouping/position-grouping.service';

import { PageUrlEnum } from '@models/page-url.enum';

@Injectable({
  providedIn: 'root',
})
export class BalanceByDateService {
  private byDate: Observable<ByDateAccountData>;
  public managed: Account[] = [];
  public selfManaged: Account[] = [];
  public pageURL: Subject<string> = new ReplaySubject<string>();
  public byDateAccountData: Subject<ByDateAccountData> = new ReplaySubject<ByDateAccountData>();
  public selectedDate: string;
  public noDataAvailableForSelectDate: Subject<boolean> = new Subject<boolean>();

  constructor(
    private byDateGQL: ByDateGQL,
    private gqlService: GraphQLService,
    private loggerService: LoggerService,
    private assetTypeService: AssetTypeService,
    private positionGroupingService: PositionGroupingService,
    private commonService: CommonService,
  ) {}

  public getByDate(): Subject<ByDateAccountData> {
    return this.byDateAccountData;
  }

  public fetchByDate(date: string | undefined): Observable<ByDateAccountData> {
    this.commonService.loading.next(true);
    // When date is not defined, default to yesterday's date
    if (!date) {
      const today = new Date();
      const yesterday = today.setDate(today.getDate() - 1);
      date = this.commonService.stringifyDate(new Date(yesterday));
    }
    this.selectedDate = date;
    const byDateArgs: ByDateQueryVariables = {
      date: date,
    };
    return this.gqlService.query<ByDateQuery>(this.byDateGQL.document, false, byDateArgs).pipe(
      delay(50),
      map((gqlResponse: ApolloQueryResult<ByDateQuery>) => {
        return this.mapBalanceByDateData(gqlResponse.data);
      }),
      tap(() => this.commonService.loading.next(false)),
      tap((accountData: ByDateAccountData) => this.byDateAccountData.next(accountData)),
    );
  }

  // eslint-disable-next-line complexity
  private accountProductChecker(account: any): boolean {
    let result: boolean =
      account.productType !== 'OutsideHoldings' &&
      account.productType !== 'Vanguard529Plan' &&
      account.productType !== 'Yodlee'
        ? true
        : false;
    if (this.commonService.getGatekeeperToggleValue() === false) {
      result =
        account.productType !== 'MutualFund' &&
        account.productType !== 'MutualFundRetirement' &&
        account.productType !== 'LegacyBrokerage'
          ? true
          : false;
    }
    return result;
  }

  private mapBalanceByDateData(byDateResponseData: ByDateQuery): ByDateAccountData {
    try {
      if (!byDateResponseData) {
        throw new Error('No GQL response data');
      }
      return this.byDateData(byDateResponseData);
    } catch (error) {
      return this.buildByDateIfError(error);
    }
  }

  private byDateData(byDateResponseData: ByDateQuery): ByDateAccountData {
    this.commonService.setAdditionalProperties(byDateResponseData.accountAggregator.accounts);
    let byDate = this.getDefaultBalanceByDate();
    byDate.balancesByDate = this.mapByDateData(byDateResponseData);
    this.commonService.setHasMergedAccounts(byDate.balancesByDate.accounts);
    return byDate;
  }
  private buildByDateIfError(error): ByDateAccountData {
    this.logEvent(error);
    return this.getDefaultBalanceByDate();
  }

  private logEvent(error): void {
    this.loggerService.error({
      feature: 'BalanceByDateService',
      message: 'Failed to retrieve byDate info',
      url: PageUrlEnum.BALANCE_BY_DATE,
      logCode: LoggerCode.BYDATE_DATA_UNAVAIL_ERROR,
      error: error,
    });
  }

  public getDefaultBalanceByDate(): ByDateAccountData {
    return {
      balancesByDate: undefined,
    };
  }

  private mapByDateData(byDateResponseData: ByDateQuery): ByDateData {
    const mapByDateHoldingsData = (positions): Positions[] => {
      return positions.map(
        (position): Positions => ({
          sourceAccountId: position.sourceAccountId,
          mergedDate: this.formatDate(position.mergedDate),
          isMerged: position.isMerged,
          balance: position.balance,
          cusip: position.cusip,
          accruedDividends: position.accruedDividends,
          balanceByDate: position.balanceByDate,
          fundAccountId: position.fundAccountId,
          positionId: position.positionId,
          price: position.price,
          priceAsOfDateTime: position.priceAsOfDateTime,
          securityName: position.securityName,
          securityType: position.securityType,
          settlementFund: position.settlementFund,
          shareQuantity: position.shareQuantity,
          ticker: position.ticker,
          totalBalanceByDate: position.totalBalanceByDate,
          tradeDateBalance: position.tradeDateBalance,
          todayTradeDateBalance: position.todayTradeDateBalance,
          tradeDateDollarChange: position.tradeDateDollarChange,
          tradeDatePercentChange: position.tradeDatePercentChange,
          vanguardFundId: position.vanguardFundId,
          vastAccountNumber: position.vastAccountNumber,
          assetType: this.getDetailsType(position),
          notAvailable: position.notAvailable,
          positionType: position.positionType,
          isClosed: position.isClosed,
        }),
      );
    };
    const setHasUpgradedToVBAAccount = (account: Account): boolean => {
      if (account.upgradedToVBA && account.upgradedToVBAMergedateTime) {
        return (
          new Date(this.selectedDate) <=
          new Date(account.upgradedToVBAMergedateTime.toString().slice(0, 10))
        );
      } else {
        return false;
      }
    };
    const mapByDateAccountsData: Account[] = byDateResponseData.AccountData.accounts.map(
      (account, i): Account => ({
        upgradedToVBA: account.upgradedToVBA,
        upgradedToVBAMergedateTime: account.upgradedToVBAMergedateTime,
        hasUpgradedToVBAAccount: setHasUpgradedToVBAAccount(account as Account),
        accountBeginDate: account.accountBeginDate,
        accountId: account.accountId,
        accountName: account.accountName,
        balance: account.balance,
        priceAsOfDate: this.selectedDate, // balanceAsOfDateTime refactored/renamed because BYD As Of is for Price column
        balanceByDate: account.balanceByDate,
        cashManagementAccount: account.cashManagementAccount,
        managed: account.managed,
        planId: account.planId,
        productType: account.productType,
        serviceAgreementId: account.serviceAgreementId,
        totalBalanceByDate: account.totalBalanceByDate,
        marginCode: this.findMarginCode(
          byDateResponseData.accountAggregator.accounts,
          account.accountId,
        ),
        positions: mapByDateHoldingsData(account.positions),
        groupedPositions: this.positionGroupingService.getPositionsGrouping(
          mapByDateHoldingsData(account.positions),
        ),
      }),
    );
    const mapByDatePartialErrors: PartialError[] = byDateResponseData.AccountData.partialErrors.map(
      (partialError) => ({
        code: partialError.code,
        accountId: partialError.accountId,
        description: partialError.description,
      }),
    );
    return {
      clientPoId: Number(byDateResponseData.AccountData.clientPoId),
      accounts: mapByDateAccountsData,
      partialError: mapByDatePartialErrors,
      hasEmployerPlanAccount: byDateResponseData?.AccountData?.hasEmployerPlan,
      total: {
        totalBalance: byDateResponseData.AccountData.total.totalBalance,
        totalBalanceAsOfDate: byDateResponseData.AccountData.total.totalBalanceAsOfDate,
        totalBalanceByDate: byDateResponseData.AccountData.total.totalBalanceByDate,
        totalBalanceManaged: byDateResponseData.AccountData.total.totalBalanceManaged,
        totalBalanceSelfManaged: byDateResponseData.AccountData.total.totalBalanceSelfManaged,
      } as Total,
    };
  }

  private getDetailsType(position): AssetType {
    return this.assetTypeService.getAssetTypeService(position);
  }

  findMarginCode(accounts: Account[], accountId: String): string {
    let matchedAcc = accounts.find((obj) => obj.accountId === accountId);
    return matchedAcc ? matchedAcc.marginCode : '';
  }

  public findAccountsByGroups(byDateData: ByDateAccountData): void {
    this.managed = [];
    this.selfManaged = [];
    byDateData.balancesByDate?.accounts.forEach((account) => {
      if (this.accountProductChecker(account) === true) {
        if (account.managed) {
          this.managed.push(this.getAccountItemById(byDateData.balancesByDate, account.accountId));
        } else {
          this.selfManaged.push(
            this.getAccountItemById(byDateData.balancesByDate, account.accountId),
          );
        }
      }
    });
    this.managed = this.managed.filter((item) => item);
    this.selfManaged = this.selfManaged.filter((item) => item);
    this.commonService.setDataAvailability(byDateData?.balancesByDate?.accounts);
    this.noDataAvailableForSelectDate.next(
      this.managed.length === 0 && this.selfManaged.length === 0,
    );
  }

  private getAccountItemById(byDate: ByDateData, accountId: string): Account {
    return byDate.accounts.filter((byDateAccount) => byDateAccount.accountId === accountId)[0];
  }

  public getGroupedAccounts(): GroupedAccounts {
    return {
      managedAccounts: this.managed,
      selfManagedAccounts: this.selfManaged,
    };
  }

  public getAccountGroupHeaders(byDateData: ByDateAccountData): AccountHeaders {
    return {
      managed: {
        title: content.bydLayout.accountGroupingHeaders.managedHeader,
        amount: byDateData?.balancesByDate?.total?.totalBalanceManaged,
      },
      selfManaged: {
        title: content.bydLayout.accountGroupingHeaders.selfManagedHeader,
        amount: byDateData?.balancesByDate?.total?.totalBalanceSelfManaged,
      },
    };
  }
  private formatDate(date: string): string {
    if (date !== undefined && date !== null) {
      const parts = date.split('-');
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];
      return `${month}/${day}/${year}`;
    }
    return undefined;
  }

  public getTotalBalance(byDateData: ByDateAccountData): Total {
    return byDateData?.balancesByDate?.total;
  }
  //
  // setAccountMarginCode(byDateResponseData: ByDateQuery): void {
  //   byDateResponseData.accountAggregator.accounts.forEach((account, i) => {
  //     return account[i].marginCode;
  //   });
  // }
}
