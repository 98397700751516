import { Injectable } from '@angular/core';
import { AssetType } from '@app/core/services/asset-grouping/asset-type.service';
import { Positions, PositionsGroup, PositionGroupingType } from '@models/byDateYearEndTypes';
import { SortablePositionsColumn } from '@app/core/services/sortable-holding-column/sortable-holdings-column';
import { TableSort } from '@app/core/utils/table-sort/table-sort';

@Injectable({
  providedIn: 'root',
})
export class PositionGroupingService {
  defaultSortedBy: SortablePositionsColumn = 'ticker';
  private toGroupingType: Record<AssetType, PositionGroupingType> = {
    Vanguard_Mutual_Fund: 'Mutual_Fund',
    Mutual_Fund: 'Mutual_Fund',
    Money_Market_Fund: 'Mutual_Fund',
    Vanguard_ETF: 'ETF',
    ETF: 'ETF',
    Stock: 'Stock',
    Option: 'Option',
    Bond: 'Bond',
    CD: 'CD',
    Vanguard_Cash_Plus: 'Vanguard_Cash_Plus',
    Vanguard_Cash_Deposit: 'Vanguard_Cash_Deposit',
  };

  getPositionsGrouping(positions: Positions[]): PositionsGroup[] {
    const positionsGroupedByAssetType = new Map<AssetType, PositionsGroup>();
    positions.forEach((position: Positions) => {
      const assetType = position.assetType;
      if (assetType) {
        const groupingType = this.getPositionsGroupingType(assetType);
        let positionsGroup = positionsGroupedByAssetType.get(assetType);
        if (!positionsGroup) {
          positionsGroup = { positions: [], groupingType: groupingType };
          positionsGroupedByAssetType.set(assetType, positionsGroup);
        }
        positionsGroup.positions.push(position);
      }
    });
    return this.getOrderedGrouping(positionsGroupedByAssetType);
  }

  private getPositionsGroupingType(detailsType: AssetType): PositionGroupingType {
    return this.toGroupingType[detailsType];
  }

  private getOrderedGrouping(
    positionsGroupedByAssetType: Map<AssetType, PositionsGroup>,
  ): PositionsGroup[] {
    const positionsGroups: PositionsGroup[] = [];
    const mutualFunds: Positions[] = this.groupMutualFunds(positionsGroupedByAssetType);
    const etfs: Positions[] = this.groupETFs(positionsGroupedByAssetType);
    const stocks: Positions[] = this.groupStocksAndOptions(positionsGroupedByAssetType);

    if (mutualFunds.length) {
      positionsGroups.push({ groupingType: 'Mutual_Fund', positions: mutualFunds });
    }

    if (etfs.length) {
      positionsGroups.push({ groupingType: 'ETF', positions: etfs });
    }

    if (stocks.length) {
      positionsGroups.push({
        groupingType: positionsGroupedByAssetType.has('Option') ? 'Option' : 'Stock',
        positions: stocks,
      });
    }

    if (positionsGroupedByAssetType.has('Bond')) {
      const bonds: Positions[] = positionsGroupedByAssetType.get('Bond')!.positions;
      positionsGroups.push({
        groupingType: 'Bond',
        positions: TableSort.sortTableBy(bonds, this.defaultSortedBy),
      });
    }

    if (positionsGroupedByAssetType.has('CD')) {
      const cds: Positions[] = positionsGroupedByAssetType.get('CD')!.positions;
      positionsGroups.push({
        groupingType: 'CD',
        positions: TableSort.sortTableBy(cds, this.defaultSortedBy),
      });
    }

    if (positionsGroupedByAssetType.has('Vanguard_Cash_Plus')) {
      const cashPlus: Positions[] = positionsGroupedByAssetType.get('Vanguard_Cash_Plus')!.positions;
      positionsGroups.push({
        groupingType: 'Vanguard_Cash_Plus',
        positions: TableSort.sortTableBy(cashPlus, this.defaultSortedBy),
      });
    }

    if (positionsGroupedByAssetType.has('Vanguard_Cash_Deposit')) {
      const cashDeposit: Positions[] = positionsGroupedByAssetType.get('Vanguard_Cash_Deposit')!.positions;
      positionsGroups.push({
        groupingType: 'Vanguard_Cash_Deposit',
        positions: TableSort.sortTableBy(cashDeposit, this.defaultSortedBy),
      });
    }

    return positionsGroups;
  }

  private groupMutualFunds(
    positionsGroupedByAssetType: Map<AssetType, PositionsGroup>,
  ): Positions[] {
    const mutualFunds: Positions[] = [];
    const vanguardMutualFunds: Positions[] | undefined =
      positionsGroupedByAssetType.get('Vanguard_Mutual_Fund')?.positions;
    const nonVanguardMutualFunds: Positions[] | undefined =
      positionsGroupedByAssetType.get('Mutual_Fund')?.positions;
    const moneyMarketFunds: Positions[] | undefined =
      positionsGroupedByAssetType.get('Money_Market_Fund')?.positions;
    const nonVanguardAndMoneyMarketFunds: Positions[] = [];

    if (vanguardMutualFunds) {
      mutualFunds.push(...TableSort.sortTableBy(vanguardMutualFunds, this.defaultSortedBy));
    }
    if (nonVanguardMutualFunds) {
      nonVanguardAndMoneyMarketFunds.push(...nonVanguardMutualFunds);
    }
    if (moneyMarketFunds) {
      nonVanguardAndMoneyMarketFunds.push(...moneyMarketFunds);
    }
    if (nonVanguardAndMoneyMarketFunds.length) {
      mutualFunds.push(
        ...TableSort.sortTableBy(nonVanguardAndMoneyMarketFunds, this.defaultSortedBy),
      );
    }
    return mutualFunds;
  }

  private groupETFs(positionsGroupedByAssetType: Map<AssetType, PositionsGroup>): Positions[] {
    const etfs: Positions[] = [];
    const vanguardEtfs: Positions[] | undefined =
      positionsGroupedByAssetType.get('Vanguard_ETF')?.positions;
    const nonVanguardEtfs: Positions[] | undefined =
      positionsGroupedByAssetType.get('ETF')?.positions;

    if (vanguardEtfs) {
      etfs.push(...TableSort.sortTableBy(vanguardEtfs, this.defaultSortedBy));
    }
    if (nonVanguardEtfs) {
      etfs.push(...TableSort.sortTableBy(nonVanguardEtfs, this.defaultSortedBy));
    }
    return etfs;
  }

  private groupStocksAndOptions(
    positionsGroupedByAssetType: Map<AssetType, PositionsGroup>,
  ): Positions[] {
    const stocksAndOptions: Positions[] = [];
    const stocks: Positions[] | undefined = positionsGroupedByAssetType.get('Stock')?.positions;
    const options: Positions[] | undefined = positionsGroupedByAssetType.get('Option')?.positions;

    if (stocks) {
      stocksAndOptions.push(...stocks);
    }
    if (options) {
      stocksAndOptions.push(...options);
    }
    return TableSort.sortTableBy(stocksAndOptions, this.defaultSortedBy);
  }
}
