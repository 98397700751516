import { Component } from '@angular/core';
import {ContentDirective} from '@app/core/directives/content.directive'
import { LinkModule } from '@vg-constellation/angular-13/link';


@Component({
  standalone: true,
  selector: 'byd-customize-view',
  imports: [LinkModule,ContentDirective],
  templateUrl: './customize-view.component.html',
  styleUrls: ['./customize-view.component.scss'],
})
export class CustomizeViewComponent  {

  constructor() {}
}
