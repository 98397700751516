<ng-container content [bundleName]="'bydToolbar'" #content="bundle" id="mainContent">
  <div [ngClass]="{ invisible: isLoading }" class="pb-md-4">
    <div class="d-flex align-items-left flex-column flex-md-row py-3 py-md-0">
      <div class="pe-md-4">
        <div class="d-flex align-items-center label-dropdown-container">
          <span class="c11n-text-md-bold pe-3">{{ content['show'] }}</span>
          <holdings-page-navigation
            class="page-selector"
            [attr.activeItem]="pageSelectorActiveItem"
          ></holdings-page-navigation>
        </div>
      </div>

      <byd-date-selector
        *ngIf="isDateSelectorVisible && earliestAccountBeginDate"
        [earliestAccountDate]="earliestAccountBeginDate"
      ></byd-date-selector>
    </div>
  </div>

  <ng-container *ngIf="!isLoading">

    <!-- Announcement banner -->
    <c11n-banner
      *ngIf="!isDataUnavailable"
      [size]="'small'"
      [headingText]="''"
      [hasCloseControl]="true"
      [hasDetails]="false"
      [closeAriaLabel]="'Remove Banner'"
      [variant]="'basic'"
      data-testid="announcement-banner"
    >
      <div class="c11n-text-sm--crop">
        <span *ngIf="inputUrlPath === '/by-date'" [innerHTML]="content['bannerPriorTo']"></span>
        <span [innerHTML]="content['bannerText']"></span>
      </div>
    </c11n-banner>
    <br />

    <!--  Banner for 403 accounts  -->
    <ng-container *ngIf="isFourHundredThreeAccount && !isDataUnavailable">
      <c11n-banner
        [size]="'small'"
        [headingText]="''"
        [hasCloseControl]="true"
        [hasDetails]="false"
        [closeAriaLabel]="'Remove Banner'"
        [variant]="'basic'"
        data-testid="fourHundred-three-banner"
      >
        <div
          class="c11n-text-sm--crop"
          [innerHTML]="content['fourHundredThreeAccountbannerText']"
        ></div>
      </c11n-banner>
      <br />
    </ng-container>

    <!--  Data unavailable banner  -->
    <ng-container *ngIf="isDataUnavailable">
      <c11n-banner
        [size]="'medium'"
        [variant]="'error'"
        [headingText]="'Data unavailable'"
        data-testid="data-unavailable-banner"
      >
        {{ content['dataUnavailableText'] }}
      </c11n-banner>
      <br />
    </ng-container>

    <!--  TODO: This banner currently unused. Confirm this is unnecessary and we can remove the logic.  -->
    <c11n-banner
      *ngIf="noDataAvailableForSelectDate && !isDataUnavailable"
      [size]="'medium'"
      [variant]="'error'"
      [headingText]="'No Accounts for selected dates'"
      data-testid="no-accounts-for-selected-date-available-banner"
    >
      {{ content['dateSelectedUnavailableText'] }}
    </c11n-banner>
    <!--    -->

    <!--  Expand/Collapse Links  -->
    <ng-container *ngIf="!isDataUnavailable && !noDataAvailableForSelectDate">
      <byd-expand-collapse data-testid="expand-collapse-links"></byd-expand-collapse>
    </ng-container>
  </ng-container>
</ng-container>
