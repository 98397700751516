import { DOMAINS } from '@content/domains';

export const content = {
  pageTitles: {
    BYD: 'Balances by Date | Vanguard',
    BYE: 'Balances Compared to Year End | Vanguard',
  },

  customizeAccount: {
    preLinkLabel: "Visit",
    label: 'customized view',
    postLinkLabel: 'to personalize the accounts you\'d like to display or hide.',
    url: `${DOMAINS.CUSTOMIZE_VIEW}`,
  },


  bydLayout: {
    expandAll: 'Expand all accounts',
    collapseAll: 'Collapse all accounts',
    resetSorting: 'Reset sorting',
    totalAssetsByDate: 'Total assets by date',
    totalAssetYearEnd: 'Total assets',
    notAvailable: 'This holding was not owned, and had no shares, for the selected time period.',
    accountGroupingHeaders: {
      managedHeader: 'Advised accounts total: ',
      selfManagedHeader: 'Self-managed accounts total: ',
      manualOutsideInvestmentsHeader: 'Outside investments you update yourself',
      autoOutsideInvestmentsHeader: 'Outside investments that are updated automatically',
    },
    mergedTooltip: {
      content1: 'This holding was merged from account ',
      content2: ' on ',
    },
  },
  bydToolbar: {
    date: 'Date:',
    show: 'Show:',
    hintText: 'Enter a valid date up to ',
    errorText: 'Date entered must be up to ',
    printPage: 'Print page',
    bannerPriorTo:
      'Information prior to January 2, 2011, is only available for Vanguard mutual funds and Vanguard ETFs&#174;.  ',
    bannerText:
      'Historical data is unavailable for mutual fund-only accounts. We’re currently working to resolve the problem.  We apologize for the inconvenience.',
    fourHundredThreeAccountbannerText: "This page doesn't include details of your 403(b) plan.",
    dataUnavailableText:
      'Our system is temporarily unable to display some of your account information. We apologize for the inconvenience and appreciate your patience while we fix these issues. Please refresh in a few minutes or choose another page.',
    accountsUnavailableText:
      "Our mutual fund-only investment platform will be retiring soon. To continue viewing all your account details, you'll need to transition to a ",
    dateSelectedUnavailableText: "There are no accounts based on the date you selected.",
    mergedAccountBannerHeading: `We're making updates`,
    mergedAccountsBanner: {
      text1:
        'Our system is incorrectly displaying accrued dividends for some merged accounts as $0.00. Please visit the ',
      linkText: 'statements page',
      url: `${DOMAINS.STATEMENTS}`,
      text2:
        'for the correct accrued dividends. We appreciate your patience while we fix this issue.',
    },
  },
  pageSelectorContent: [
    {
      title: '',
      items: [
        {
          name: 'Summary information',
          url: `${DOMAINS.HOLDINGS}/`,
          routerLink: '',
        },
        {
          name: 'Balances by date',
          url: `${DOMAINS.BYD}`,
          routerLink: '/by-date',
        },
        {
          name: 'Balances compared to year-end',
          url: `${DOMAINS.BYE}`,
          routerLink: '/compared-to-year-end',
        },
        {
          name: 'Prices & returns',
          url: `${DOMAINS.HOLDINGS}/prices-and-returns`,
          routerLink: '',
        },
      ],
    },
    {
      title: 'Cost basis',
      items: [
        {
          name: 'Unrealized gains/losses',
          url: `${DOMAINS.COST_BASIS}/unrealized`,
          routerLink: '',
        },
        {
          name: 'Realized gains/losses',
          url: `${DOMAINS.COST_BASIS}/realized`,
          routerLink: '',
        },
        { name: 'Gifted shares', url: `${DOMAINS.COST_BASIS}/gifted`, routerLink: '' },
      ],
    },
    {
      title: 'Dividends & capital gains',
      items: [
        {
          name: 'Taxable gains',
          url: `${DOMAINS.DTG_TAXABLE}`,
          routerLink: '',
        },
        {
          name: 'Nontaxable gains',
          url: `${DOMAINS.DTG_NONTAXABLE}`,
          routerLink: '',
        },
        {
          name: 'Distribution payouts',
          url: `${DOMAINS.PERSONAL}/us/myaccounts/divcapgains`,
          routerLink: '',
        },
      ],
    },
  ],
  bydStaticContent: {
    content1:
      "When an entire position within an account has been sold during a calendar year, information on the Balances compared to year-end pages may differ from that shown on your account's statements. The Balances by date and Balances by year-end pages only reflect information for currently held positions in your account.",
    content2:
      'Vanguard funds not held in a brokerage account are held by The Vanguard Group, Inc. and are not protected by SIPC.',
    content3:
      '*Brokerage assets are held by Vanguard Brokerage Services, a division of Vanguard Marketing Corporation, member',
    content4: 'Prices are as of market close for the date selected.',
    content5:
      'When an entire position within an account has been sold during a calendar year, information on the Balances by date and Balances compared to year-end pages may differ from that shown on your account’s statements. The Balances by date and Balances compared to year-end pages only reflect information for currently held positions in your account.',
    content9:
      '**Values shown include your mutual fund account before your mutual fund assets were transferred into an upgraded brokerage account.',
    FINRA: {
      url: 'https://www.finra.org/investors#/',
      label: 'FINRA',
    },
    SIPC: {
      url: 'https://www.sipc.org/',
      label: 'SIPC.',
    },
    relatedLinks: {
      header: 'Related links',
      createValuationReportLink: {
        label: 'Create balance verification letter',
        url: `${DOMAINS.PERSONAL1}/avl-account-valuation-letter-angular/landing-page`,
      },
      viewValuationReportsLink: {
        label: 'View existing account valuation reports',
        url: `${DOMAINS.PERSONAL}/us/faces/web/accountvaluationtool/view/ViewExistingReports.xhtml`,
      },
      employerPlanBalanceByDateLink: {
        label: 'View employer plan balances by date',
        url: `${DOMAINS.PERSONAL}/us/LinktoMEP?hostname=GS_PE_HOSTNAME&url=GS_PE_PLAN_SUMMARY`,
      },
    },
    content6:
      'Information prior to January 2, 2011 is only available for Vanguard mutual funds and Vanguard ETFs®',
    content7: 'Related Links',
    content8: ' and',
  },
  bydTableContent: {
    content1: 'Symbol',
    content2: 'Name',
    content3: 'Fund & Account',
    content4: 'Year-end value',
    content5: 'Balance',
    content6: '% Change',
    content7: '$ Change',
    content8: 'Total assets',
    content9: 'Quantity',
    content10: 'Price',
    content11: 'Accrued dividends',
    content12: 'Total balance',
    content13: 'Total',
    content14: 'Total assets by date',
    content15: 'As of',
    content16: '(Settlement Fund)',
    content17: 'Trade Data Balance',
    content18: 'Settlement fund accrued dividends',
    content19:
      'The net dollar amount in your account that has not swept to or from your settlement fund.',
  },
  asOfContent: {
    content1: 'As of',
    content2: '4:00 p.m., ET',
  },
  settlementFund: {
    title: 'Settlement fund',
    labelText: '(Settlement fund)',
    tradeDateBalance: 'Trade Date Balance',
    settlementFundAccruedDividends: 'Settlement fund accrued dividends',
    tooltipText:
      'The net dollar amount in your account that has not swept to or from your settlement fund',
    cashPlusLabelText: 'Vanguard Cash Plus (Bank sweep program)',
  },
  changeValue: {
    up: 'Indicates positive change in value',
    down: 'Indicates negative change in value',
  },
  adobeTrackingContent: {
    vbaLinkBYD: 'Vanguard Brokerage Account_balances by date',
    makeTheChangeBYD: 'Make the change now_balances by date',
    vbaLinkBYE: 'Vanguard Brokerage Account_balances compared to year end',
    makeTheChangeBYE: 'Make the change now_balances compared to year end',
    location: 'secure site holdings banner',
    vbaBannerCloseBtnBYD: 'close banner_balances by date',
    vbaBannerCloseBtnBYE: 'close banner_balances compared to year end',
  },
};
