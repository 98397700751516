import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, Input,
  OnDestroy,
  OnInit,
  CUSTOM_ELEMENTS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BannerModule } from '@vg-constellation/angular-15/banner';
import { IconModule } from '@vg-constellation/angular-15/icon';
import { DateSelectorComponent } from '@app/core/components/date-selector/date-selector.component';
import { ExpandCollapseComponent } from '@app/core/components/expand-collapse/expand-collapse.component';
import { ContentDirective } from '@app/core/directives/content.directive';
import { BalanceByDateService } from '@app/core/services/balance-by-date/balance-by-date.service';
import { PageUrlEnum } from '@models/page-url.enum';
import { Subscription } from 'rxjs';
import { CommonService } from '@app/core/services/common/common.service';
import { YearEndService } from '@app/core/services/yearend/yearend.service';
import { LinkComponent } from '@vg-constellation/angular-15/link';
import { ButtonComponent } from '@vg-constellation/angular-15/button';
import { environment } from '@environments/environment';
import { AdobeLaunchService } from '@vanguard/secure-site-components-lib';
import { content } from "@content/content";

@Component({
  standalone: true,
  selector: 'byd-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    CommonModule,
    BannerModule,
    IconModule,
    DateSelectorComponent,
    ExpandCollapseComponent,
    ContentDirective,
    LinkComponent,
    ButtonComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ToolbarComponent implements OnInit, OnDestroy {
  public isDateSelectorVisible: boolean;
  public isFourHundredThreeAccount: boolean;
  // public hasHiddenAccount: boolean;
  public isDataUnavailable: boolean;
  public isLoading: boolean = true;
  public hasEmployerPlan: boolean = false;
  public subscription = new Subscription();
  public noDataAvailableForSelectDate: boolean = false;
  public isBYDPage: boolean;
  public makeTheChangeUrl: string = environment.MAKE_THE_CHANGE_NOW_URL;
  public vanguardBrokerageUrl: string = environment.VANGUARD_BROKERAGE_ACCOUNT_URL;
  public earliestAccountBeginDate: string;
  @Input() inputUrlPath: string;
  public pageSelectorActiveItem: string = 'holdings-summary';
  public pageNameLabel: string = 'holdings-summary';
  public hasMergedAccount: boolean;


  constructor(
    private balanceByDateService: BalanceByDateService,
    private yearEndService: YearEndService,
    private changeDetector: ChangeDetectorRef,
    private commonService: CommonService,
    private adobeLaunchService: AdobeLaunchService,
  ) {}


  ngOnInit(): void {
    this.setPageLoadingStatus();
    this.subscription = this.balanceByDateService.pageURL.subscribe((url) => {
      this.isDateSelectorVisible = url === `/${PageUrlEnum.BALANCE_BY_DATE}`;
      this.changeDetector.markForCheck();
    });

    this.subscription.add(
      this.commonService.isDataUnavailable.subscribe((isDataUnavailable) => {
        this.isDataUnavailable = isDataUnavailable;
        this.changeDetector.markForCheck();
      }),
    );
    this.subscription.add(
      this.commonService.additionalProperties.subscribe((props: any) => {
        this.isFourHundredThreeAccount = props.isFourHundredThreeAccount;
        // this.hasHiddenAccount = props.hasHiddenAccount;
        this.earliestAccountBeginDate = props.earliestAccountBeginDate || '1975-01-01';
        this.changeDetector.markForCheck();
      }),
    );
    this.subscription.add(
      this.commonService.hasMergedAccounts.subscribe((props: any) => {
        this.hasMergedAccount = props;
        this.changeDetector.markForCheck();
      }),
    );
    this.subscription.add(
      this.balanceByDateService.noDataAvailableForSelectDate.subscribe(
        (noDataAvailableForSelectDate: boolean) =>
          this.setNoAccountsAvailableForSelectedDate(noDataAvailableForSelectDate),
      ),
    );
    this.setCurrentPage();
  }

  private setPageLoadingStatus(): void {
    this.subscription.add(
      this.commonService.loading.subscribe((loading: boolean) => this.setLoadingStatus(loading)),
    );
  }

  private setCurrentPage(): void {
    this.balanceByDateService.pageURL.subscribe((pageName) => {
      if(pageName.includes('by')){
        this.pageSelectorActiveItem = 'balance-by-date';
        this.pageNameLabel = "Balances By Date"
      }
      if(pageName.includes('year')){
        this.pageSelectorActiveItem = 'balance-year-end';
        this.pageNameLabel = "Balances Compared to Year End"
      }
    });
  }

  setLoadingStatus(loading: boolean): void {
    this.isLoading = loading;
    this.changeDetector.markForCheck();
  }

  setNoAccountsAvailableForSelectedDate(noDataAvailableForSelectDate: boolean): void {
    this.noDataAvailableForSelectDate = noDataAvailableForSelectDate;
    this.changeDetector.markForCheck();
  }
  
  setHasMergedAccounts(upgradedToVBA: boolean): void {
    this.hasMergedAccount = upgradedToVBA;
    this.changeDetector.markForCheck();
  }

  trackVBABanner(elementType:string): void{

    this.balanceByDateService.pageURL
    .subscribe(url => this.isBYDPage = url === `/${PageUrlEnum.BALANCE_BY_DATE}`);

    const ctaNameVBAString = this.isBYDPage ? content.adobeTrackingContent.vbaLinkBYD: content.adobeTrackingContent.vbaLinkBYE;
    const ctaNameMakeTheChange = this.isBYDPage ? content.adobeTrackingContent.makeTheChangeBYD : content.adobeTrackingContent.makeTheChangeBYE;
    const ctaNameCloseBtn = this.isBYDPage ? content.adobeTrackingContent.vbaBannerCloseBtnBYD: content.adobeTrackingContent.vbaBannerCloseBtnBYE;
    this.adobeLaunchService.trackEvent({
      location:  content.adobeTrackingContent.location,
      ctaName: elementType ==="link"? ctaNameVBAString: elementType ==="btn"?ctaNameMakeTheChange:ctaNameCloseBtn,
      ctaType: elementType ==="link"? "link":"button",
    });
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
