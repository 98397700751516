import {ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DatePickerModule } from '@vg-constellation/angular-15/date-picker';
import { BalanceByDateService } from '@app/core/services/balance-by-date/balance-by-date.service';
import { ContentDirective } from '@app/core/directives/content.directive';
import { Subscription } from 'rxjs';
import { ButtonComponent } from "@vg-constellation/angular-15/button";
import { CommonService } from "@app/core/services/common/common.service";

@Component({
  standalone: true,
  selector: 'byd-date-selector',
  templateUrl: './date-selector.component.html',
  styleUrls: ['./date-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, DatePickerModule, ContentDirective, ButtonComponent],
})
export class DateSelectorComponent implements OnInit, OnDestroy {
  selectedDate: string;
  maxSelectedDate: string;
  minSelectedDate: string;
  fetchByDateSubscription: Subscription;
  showErrorText: boolean = false;
  @Input() earliestAccountDate: string;// data comes from toolbar, defaults to 1971-01-01

  constructor(
    private balanceByDateService: BalanceByDateService,
    public commonService: CommonService
    ) {}
  public ngOnInit(): void {
    this.maxSelectedDate = this.selectedDate = this.getYesterdayDate();
    this.minSelectedDate = '1971-01-01';
    this.addTileToDatePicker();

  }

  getYesterdayDate(): string {
    const today = new Date();
    const yesterday = today.setDate(today.getDate() - 1);
    return this.commonService.stringifyDate(new Date(yesterday));
  }

   public onButtonClick(event): void {
     this.fetchByDateSubscription = this.balanceByDateService
       .fetchByDate(this.selectedDate)
       .subscribe();
  }

  public onDateChanged(event: any): void {
    if (event.target.value.length > 0
      && event.target.value >= this.minSelectedDate
      && event.target.value <= this.maxSelectedDate) {
      this.selectedDate = event.target.value;
      this.normalizeDate();
      this.showErrorText = false;
    } else {this.showErrorText = true}
  }

  normalizeDate(): void {
    let selectedDateMs = new Date(this.selectedDate).getTime();
    let currentDate = this.commonService.stringifyDate(new Date());
    let currentDateMs = new Date(currentDate).getTime();
    if (selectedDateMs > currentDateMs) {
      this.selectedDate = currentDate;
    }
  }

  addTileToDatePicker(): void {
    let datePickerTitle = document.getElementById('balance-date');
    datePickerTitle.setAttribute("label", "Date Picker");
  }

  ngOnDestroy(): void {
    this.fetchByDateSubscription?.unsubscribe();
  }
}
