import { Injectable } from '@angular/core';
import { ReplaySubject, Subject } from "rxjs";
import {Account, YearEndData, ByDateData, YearEndAccountData, Positions} from "@models/byDateYearEndTypes";
import {TableSort} from "@app/core/utils/table-sort/table-sort";
import {SortablePositionsColumn} from "@app/core/services/sortable-holding-column/sortable-holdings-column";

import {PositionGroupingService} from "@app/core/services/asset-grouping/position-grouping.service";
import { HoldingDetailURLService } from "@app/core/services/holdings-detail-url/holdings-detail-url.service";
import { ProductTypeEnum } from "@models/product-type.enum";
import { FeatureId, GatekeeperService } from '@app/core/services/gatekeeper/gatekeeper.service';


@Injectable({
  providedIn: 'root',
})
export class CommonService {
  public isDataUnavailable: Subject<boolean> = new ReplaySubject<boolean>();
  public additionalProperties: Subject<any> = new ReplaySubject<any>();
  public loading: Subject<boolean> = new Subject<boolean>();
  public hasMergedAccounts: Subject<boolean> = new Subject<boolean>();
  private isInternalGatekeeperToggleOn: boolean = false;

  constructor(
    public positionGroupingService: PositionGroupingService,
    private holdingDetailURLService: HoldingDetailURLService,
    private gatekeeperService: GatekeeperService,
  ) {}

  public getGatekeeperToggleValue(): boolean {
    return this.isInternalGatekeeperToggleOn;
  }

  public gatekeeperToggleChecker(featureId: FeatureId) {
    this.gatekeeperService.isFeatureSwitchedOn(featureId).subscribe((displayTaAccounts) => {
      this.isInternalGatekeeperToggleOn = displayTaAccounts;
    });
  }

  setDataAvailability(accounts: any): void {
    this.isDataUnavailable.next(!accounts);
  }
  setAdditionalProperties(accounts: Account[]): void {
    this.additionalProperties.next({
      isFourHundredThreeAccount: accounts.some(this.fourHundredThreeAccount),
      hasHiddenAccount: accounts.some(this.hiddenAccounts),
      earliestAccountBeginDate: this.getEarliestAccountBeginDate(accounts),
    });
  }

  public setHasMergedAccounts(accounts: Account[] | null): void {
    this.hasMergedAccounts.next(accounts?.some((account) => account.upgradedToVBA) || false);
  }

  public getEarliestAccountBeginDate(accounts): string {
    let earliestDate: string = '9999-01-01';

    accounts.forEach((account) => {
      if (
        account.accountBeginDate &&
        account.accountBeginDate.length > 0 &&
        earliestDate > account.accountBeginDate
      ) {
        earliestDate = account.accountBeginDate;
      }
    });

    if (earliestDate === '9999-01-01') {
      earliestDate = '1975-01-01';
    }
    return earliestDate;
  }

  public stringifyDate(date: Date): string {
    let dateArray = date
      .toLocaleDateString('en-US', {
        timeZone: 'America/New_York',
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
      })
      .split('/');
    return dateArray[2] + '-' + dateArray[0] + '-' + dateArray[1];
  }

  fourHundredThreeAccount(account: Account): boolean {
    return account.productType === ProductTypeEnum.RETIREMENT_403_B;
  }
  hiddenAccounts(account: Account): boolean {
    return (
      account.productType === ProductTypeEnum.MUTUAL_FUND_RETIREMENT ||
      account.productType === ProductTypeEnum.MUTUAL_FUND ||
      account.productType === ProductTypeEnum.LEGACY_BROKERAGE
    );
  }

  onHeaderClick(
    event: Event,
    tableId: string,
    property: SortablePositionsColumn,
    accountSorter?: any,
    reset?: boolean,
    account?: Account,
  ): any {
    TableSort.updateHeaderLabelSymbols(event, tableId, reset);
    if (reset) {
      account.groupedPositions = this.positionGroupingService.getPositionsGrouping(
        account.positions,
      );
      account.groupedPositions =
        this.holdingDetailURLService.setDetailsUrlForGroupedPositions(account);
    } else {
      const propertyToSortBy = accountSorter[account.accountId] === property ? undefined : property;
      account.groupedPositions.forEach((group) => {
        const sortedGroupHoldings = TableSort.sortTableBy(group.positions, propertyToSortBy);
        const changedOrder = group.positions !== sortedGroupHoldings;
        if (changedOrder) {
          accountSorter[account.accountId] = property;
        }
        group.positions = sortedGroupHoldings;
      });
    }
  }

  holdingSuffix(position: Positions, accountMarginCode: String): string | undefined {
    if (position) {
      if (accountMarginCode === 'MARGIN') {
        return this.marginSuffix(position);
      } else {
        return this.nonMarginSuffix(position);
      }
    } else {
      return undefined;
    }
  }

  marginSuffix(position: Positions): string | undefined {
    if (position.positionType === 'CASH') {
      return position.securityName + ' (Cash)';
    } else if (position.positionType === 'SHORT') {
      return position.securityName + ' (Short)';
    } else if (position.positionType === 'WHEN_ISSUED') {
      return position.securityName + ' (When issued)';
    } else {
      return position.securityName;
    }
  }

  nonMarginSuffix(position: Positions): string | undefined {
    if (position.positionType === 'SHORT') {
      return position.securityName + ' (Short)';
    } else if (position.positionType === 'MARGIN') {
      return position.securityName + ' (Margin)';
    } else if (position.positionType === 'WHEN_ISSUED') {
      return position.securityName + ' (When issued)';
    } else {
      return position.securityName;
    }
  }

  accountSuffix(account: Account): string | undefined {
    if (account) {
      if (account.marginCode === 'CASH') {
        account.accountName = account.accountName + ' (Cash)';
        return account.accountName;
      } else if (account.marginCode === 'MARGIN') {
        account.accountName = account.accountName + ' (Margin)*';
        return account.accountName;
      } else if (account.marginCode === 'WHEN_ISSUED') {
        account.accountName = account.accountName + ' (When issued)';
        return account.accountName;
      } else {
        return account.accountName;
      }
    } else {
      return undefined;
    }
  }
}
