export type SortState = 'ascending' | 'descending';

export class TableSort {
  static buildDefaultCurrentlySortedBy<T, R>(
    tableContainers: T[],
    defaultSortedProperty: keyof R,
    tableIdProvider: (tableContainer: T) => string,
  ): Record<string, keyof R> {
    const tableIdToSortedProperty: Record<string, keyof R> = {};
    tableContainers.forEach((tableContainer) => {
      const tableId = tableIdProvider(tableContainer);
      tableIdToSortedProperty[tableId] = defaultSortedProperty;
    });
    return tableIdToSortedProperty;
  }

  static updateHeaderLabelSymbols(event: Event, tableId: string, reset?: boolean): void {
    const activeHeaderLabel = this.getActiveHeaderLabel(tableId);
    const activeHeaderLabelText = activeHeaderLabel?.textContent;
    const clickedHeaderLabel = (event.currentTarget as Element).parentElement!;
    const isActiveHeaderClicked = activeHeaderLabel === clickedHeaderLabel;
    let isActiveHeaderAscending = false;

    if (activeHeaderLabel) {
      isActiveHeaderAscending = activeHeaderLabel.classList.contains('ascending');
      activeHeaderLabel.classList.remove('c11n-table__thead-th--active-sort', 'descending', 'ascending');
      activeHeaderLabel.parentElement!.removeAttribute('aria-sort');
      activeHeaderLabel.firstElementChild!.setAttribute('aria-label', `${activeHeaderLabelText} sortable`);
      activeHeaderLabel.firstElementChild!.setAttribute('title', `Sortable`);
    }

    if (reset) {
      let symbolElement = document.getElementById(tableId)!.getElementsByClassName('ticker-label')[0];
      symbolElement.classList.add('c11n-table__thead-th--active-sort');
      this.setSortState(symbolElement, 'ascending');
    } else {
      clickedHeaderLabel.classList.add('c11n-table__thead-th--active-sort');
      if (isActiveHeaderClicked && isActiveHeaderAscending) {
        this.setSortState(clickedHeaderLabel, 'descending');
      } else {
        this.setSortState(clickedHeaderLabel, 'ascending');
      }
    }
  }

  static setSortState(clickedHeaderLabel: Element, sortState: SortState): void {
    const clickedHeaderLabelText = clickedHeaderLabel?.textContent;
    clickedHeaderLabel.classList.add(sortState);
    clickedHeaderLabel.parentElement!.setAttribute('aria-sort', sortState);
    clickedHeaderLabel.firstElementChild!.setAttribute('aria-label', `${clickedHeaderLabelText} ${sortState}`);
    clickedHeaderLabel.firstElementChild!.setAttribute('title', `Sorted ${sortState}`);
  }

  static getActiveHeaderLabel(tableId: string): Element | null {
    const theadRow = document.getElementById(tableId)?.querySelector('.c11n-table--small thead tr');
    if (theadRow) {
      const ths: HTMLCollectionOf<HTMLTableHeaderCellElement> = theadRow.getElementsByTagName('th');
      for (let i = 0; i < ths.length; i++) {
        const activeHeaderLabel = ths[i].querySelector('.c11n-table__thead-th--active-sort');
        if (activeHeaderLabel) return activeHeaderLabel;
      }
    }
    return null;
  }

  // Sorts a table on a table property. If the column (property) is currently unsorted, sorts ascending.
  // If no property is provided, reverses the order. If column is empty, does nothing.
  static sortTableBy<R>(rows: R[], propertyToSortBy: keyof R | undefined): R[] {
    if (!propertyToSortBy) {
      // Caller did not pass a column argument, so reverse the array.
      return rows.slice().reverse();
    } else {
      // Caller passed a column argument.
      // Find the first cell in the column with data available.
      const firstRowWithProperty: R | undefined = rows.find((row) => row[propertyToSortBy]);
      if (!firstRowWithProperty) {
        // Column does not have data, so do nothing.
        return rows;
      } else {
        // Column has data.
        // Determine which sort method to use based on the data type.
        if (typeof firstRowWithProperty[propertyToSortBy] === 'number') {
          return this.sortNumber(rows, propertyToSortBy);
        } else if (typeof firstRowWithProperty[propertyToSortBy] === 'string') {
          return this.sortString(rows, propertyToSortBy);
        } else {
          return rows;
        }
      }
    }
  }

  // Sort ascending with comparison done on strings.
  private static sortString<R>(rows: R[], property: keyof R): R[] {
    return rows.slice().sort((a, b) => {
      if (!a[property]) return -1;
      return b[property] ? (a[property] as unknown as string).localeCompare(b[property] as unknown as string) : 1;
    });
  }

  // Sort ascending with comparison done on numbers.
  private static sortNumber<R>(rows: R[], property: keyof R): R[] {
    return rows.slice().sort((a, b) => {
      return (a[property] as unknown as number) - (b[property] as unknown as number);
    });
  }
}
