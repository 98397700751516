import { Injectable } from '@angular/core';
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class ExpandCollapseService {
  public accordionState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
  public readonly nonUltraExpansionThreshold = 6;
  constructor() { }
}
