import {Injectable} from '@angular/core';
import {Apollo} from 'apollo-angular';
import {ApolloQueryResult, QueryOptions} from '@apollo/client/core';
import {Observable, of} from 'rxjs';
import {DocumentNode} from 'graphql';
import {LoggerCode} from '@models/logger';
import {catchError, take, timeout} from 'rxjs/operators';
import {LoggerService} from '@vanguard/secure-site-components-lib';

@Injectable({
  providedIn: 'root',
})
export class GraphQLService {
  constructor(
    private loggerService: LoggerService,
    private apollo: Apollo,
  ) {
  }

  /**
   * Makes a GraphQL query using Apollo Client's query function
   * If there are errors (it may still return a 200 OK), log it and reject the Promise
   * If no errors, extract actual data under the "data" object and resolves
   * @param query String GraphQL Query
   * @param noCache String cache options
   * @param variables GraphQL Query variables
   */
  query<T = any>(query: DocumentNode, noCache?: boolean, variables?: any): Observable<ApolloQueryResult<T>> {
    const fetchPolicyString = noCache ? 'no-cache' : 'cache-first';

    const queryObject: QueryOptions = {
      query: query,
      variables: variables,
      errorPolicy: 'all',
      fetchPolicy: fetchPolicyString,
    };

    return this.apollo.query(queryObject).pipe(
      timeout(40000),
      catchError((error) => {
        this.loggerService.error({
          feature: 'GraphQLService',
          message: error.message,
          logCode: LoggerCode.GRAPHQL_SERVICE_ERROR,
          error: error,
        });

        return of({ data: null, errors: [error] } as any);
      }),
      take(1),
    );
  }
}
