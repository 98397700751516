import {
  HttpHandlerFn,
  HttpHeaders,
  HttpInterceptorFn,
  HttpRequest,
} from '@angular/common/http';

const getHeaders = (reqHeaders: HttpHeaders): any => {
  //  authorization here
  let headers: any = {};
  return headers;
};

export const CommonInterceptorFn: HttpInterceptorFn = (
  req: HttpRequest<any>,
  next: HttpHandlerFn
) => {
  // const loaderService = inject(LoaderService);

  if (req.url.indexOf('specialParams') > -1) {
    const url = req.url + 'test';
    console.log('url updated by interceptor', url);

    const adjustedReq = req.clone({
      url: url,
      setHeaders: getHeaders(req.headers),
    });
    return next(adjustedReq);
  }
  console.log('interceptor: URL are not changed', req.url);
  return next(req);
};
