import {
  connect_policies,
  frames_policies,
  img_policies, object_policies,
  script_policies,
  style_policies,
  csp
} from "../../development/csp";

export const environment = {
  production: true,
  performance: false,
  internal: false,
  deployment: 'production',
  GLOBAL_HEADER_STYLES: 'https://ccc.vgcontent.info/vg-vgn/latest/css/vgn.min.css?appPrefix=BYD',
  GLOBAL_HEADER_JS: 'https://ccc.vgcontent.info/vg-vgn/latest/js/vgn.min.js?appPrefix=BYD',
  GLOBAL_HEADER_TAXONOMY: 'https://rgh.ecs.retp.c1.vanguard.com/taxonomy',
  CONSUMER_API: 'https://personal1.vanguard.com/byd-byd-consumer-service/',
  LOGON_URL: 'https://logon.vanguard.com/logon',
  GLOBAL_HEADER_V8_JS: 'https://n4v.web.vanguard.com/secure-site-components.js',
  GLOBAL_HEADER_V8_CSS: 'https://n4v.web.vanguard.com/nav-preload.css',
  MEDALLIA_LIB:
    'https://mdl-medallia-digital.vanguard.com/pkg/1.0.0/web/external_libs/wdcusprem/2940/onsite/medallia-digital-embed.js',
  TEALEAF_LIB: 'https://personal1.vanguard.com/byd-byd-consumer-service/tealeaf',
  GRAPHQL_URI: 'https://personal1.vanguard.com/byd-byd-consumer-service/graphql',
  LOGGER_URI: 'https://personal1.vanguard.com/byd-byd-consumer-service/api/log',
  GATEKEEPER_URI:
    'https://personal1.vanguard.com/byd-byd-consumer-service/gatekeeper',
  PERSONAL_DOMAIN: 'https://personal.vanguard.com',
  IDLE_TIMER_CONFIG: JSON.stringify({
    idleTime: 900000,
    idleWarningTime: 180000,
    absoluteWarningTime: 180000,
    logOffRedirectURL: 'https://logon.vanguard.com/logon/idlelogoff',
    idleSessionRefreshURL: 'https://ccc.vanguard.com/api/idle-timeout-refresh',
    extendedUserToggleEnabled: true
  }),
  IDLE_TIMER_STYLES: 'https://ccc.vgcontent.info/vg-idle-timer/latest/css/idle_timer.min.css',
  IDLE_TIMER_JS: 'https://ccc.vgcontent.info/vg-idle-timer/latest/js/idle_timer.min.js',
  ADOBE_LAUNCH_JS:
    'https://corp.etm.assets.vgdynamic.info/files/retail_global_prd/379f26abac75/9d4ec58961b2/launch-ecb7d9153f04.min.js',
  ADOBE_TARGET_ANTIFLICKER_JS:
    'https://corp.at2.assets.vgdynamic.info/files/js/core/antiflicker.min.js',
  ADOBE_DATA_LAYER:
    'https://personal1.vanguard.com/laa-ret-etm-adobe-launch-webapp/static/adobe-data-layer.js',
  APPDYNAMICS_CONFIG: JSON.stringify({
    appKey: 'AD-AAB-ACE-UKD',
    adrumExtUrl: 'https://ccc.vgcontent.info/vg-app-dynamics/2.0.0/js',
    spa: { spa2: true },
    xd: { enable: false },
  }),

  CCC_APPDYNAMICS_JS: 'https://ccc.vgcontent.info/vg-app-dynamics/2.0.0/js/app_dynamics.min.js',
  MAKE_THE_CHANGE_NOW_URL: "https://personal1.vanguard.com/utb-upgrade-to-brokerage/transition/verify/accounts",
  VANGUARD_BROKERAGE_ACCOUNT_URL: "https://personal1.vanguard.com/utb-upgrade-to-brokerage/explore/overview",
  GLOBAL_HEADER_TAXONOMY_2: 'https://personal1.vanguard.com/rgh-rglobal-header-ecs-webservice/current/taxonomy',
  serverlessC3Url: 'https://mc3-static-assets.web.vanguard.com/vg-ccc-serverless/latest/vg-ccc-serverless.min.js',
  c3Config: {
    site: 'retailExternal',
    rte: 'PROD',
    app: 'BYD',
    concerns: 'al,adl,it,csp,adv4',
    appConfig: {
      page: {
        pageTemplate: "holdings",
      },
      appdynamicskey: 'AD-AAB-ACE-UKD',
      csp: csp
    }
  }
};
