export const DOMAINS = {
  PERSONAL: 'https://personal.vanguard.com',
  PERSONAL1: 'https://personal1.vanguard.com',
  COST_BASIS: 'https://cost-basis.web.vanguard.com',
  RETIREMENT_PLANS: 'https://retirementplans.vanguard.com',
  APPS: 'https://apps.vanguard.com',
  HOLDINGS: 'https://holdings.web.vanguard.com',
  BYD:'https://personal.vanguard.com/us/XHTML/myaccounts/balancesbydate/',
  BYE:'https://personal.vanguard.com/us/XHTML/myaccounts/compareyearend',
  DTG_TAXABLE:'https://distributions.web.vanguard.com/taxable-gains',
  DTG_NONTAXABLE:'https://distributions.web.vanguard.com/nontaxable-gains',
  DTG_PAYOUTS: 'https://distributions.web.vanguard.com/distribution-payouts',
  STATEMENTS: 'https://statements.web.vanguard.com',
  CUSTOMIZE_VIEW: 'https://customize-view.cdn.web.vanguard.com/'
};
