import { Component } from '@angular/core';
import {LinkModule} from "@vg-constellation/angular-15/link";
import {BalanceByDateService} from "@app/core/services/balance-by-date/balance-by-date.service";
import {ContentDirective} from "@app/core/directives/content.directive";
import {ExpandCollapseService} from "@app/core/services/expand-collapse/expand-collapse.service";

@Component({
  standalone: true,
  imports: [LinkModule, ContentDirective],
  selector: 'byd-expand-collapse',
  templateUrl: './expand-collapse.component.html',
  styleUrls: ['./expand-collapse.component.scss']
})
export class ExpandCollapseComponent {

  constructor(private expandCollapseService: ExpandCollapseService) {
  }
  public expandAll(state: boolean): void {
    this.expandCollapseService.accordionState.next(state);
  }

  public get isExpanded():boolean{
    return this.expandCollapseService.accordionState.value;
  }

}
