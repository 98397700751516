import { Injectable } from '@angular/core';
import { isCashDeposit, isCashPlus } from '@app/core/utils/settlement-fund-utils/settlement-fund-utils';

export type AssetType =
  | 'Mutual_Fund'
  | 'Vanguard_Mutual_Fund'
  | 'ETF'
  | 'Vanguard_ETF'
  | 'Stock'
  | 'CD'
  | 'Bond'
  | 'Option'
  | 'Money_Market_Fund'
  | 'Vanguard_Cash_Plus'
  | 'Vanguard_Cash_Deposit';

@Injectable({
  providedIn: 'root',
})
export class AssetTypeService {
  constructor() {}
  private securityClassToDetailsType: Record<number, AssetType> = {
    0: 'Money_Market_Fund',
    1: 'Stock', // COMMON_STOCK
    2: 'Stock', // PREFERRED_STOCK
    3: 'Stock', // CONVERTIBLE_PREFERRED_STOCK
    4: 'Stock', // RIGHTS
    5: 'Stock', // WARRANT
    6: 'Stock', // UNITS
    8: 'Stock', //LIMITED PARTNERSHIP ??
    52: 'Stock', // WHEN_AS_AND_IF_ISSUED_EQUITY
    79: 'Stock', // CLOSED_END_FUND
    80: 'Stock', // COMMON_STOCK_ADR_GDR
    86: 'Stock', // COMMON_STOCK_AMERICAN_DEPOSITORY_RECEIPT
    121: 'Stock', // PREFERRED_STOCK_CONVERTIBLE
    123: 'Stock', // PREFERRED_STOCK_AMERICAN_DEPOSITORY_RECE,
    125: 'Stock', // RECEIPT
    126: 'Stock', // REMIC
    127: 'Stock', // RIGHTS_AMERICAN_DEPOSITORY_RECEIPT
    142: 'Stock', // WARRANTS
    143: 'Stock', // WARRANTS_AMERICAN_DEPOSITORY_RECEIPT
    144: 'Stock', // WFS CONVERSION
    202: 'Stock', // MISCELLANEOUS_STOCK
    76: 'ETF', // ETF
    95: 'ETF', // EXCHANGE_TRADED_FUND
    77: 'Vanguard_ETF', // VANGUARD ETF
    10: 'Option', // CALL_OPTION
    11: 'Option', // PUT_OPTION
    12: 'Option', // BANK_CALL_OPTION
    13: 'Option', // ESCROW ??,
    92: 'Option', // DEBT_OPTION_CALL
    93: 'Option', // DEBT_OPTION_PUT
    100: 'Option', // INDEX_OPTION_CALL
    101: 'Option', // INDEX_OPTION_PUT
    119: 'Option', // OPTION_CALL
    120: 'Option', // OPTION_PUT
    128: 'Option', // STOCK_OPTION_CALL
    129: 'Option', // STOCK_OPTION_PUT
    130: 'Option', // STOCK_OPTION_BANK_PLEDGE_PUT
    145: 'Option', // CURRENCY_OPTION_CALL
    146: 'Option', // CURRENCY_OPTION_PUT
    20: 'Bond', // CORPORATE_BOND
    21: 'Bond', // CONVERTIBLE_BOND
    22: 'Bond', // MUNICIPAL_BOND
    23: 'Bond', // SHORT_TERM_PAPER
    24: 'Bond', // BOND_UNIT
    25: 'Bond', // MUNI_ASSESSMENT_DIST
    30: 'Bond', // US_TREASURY_BILL
    31: 'Bond', // US_TREASURY_NOTE
    32: 'Bond', // US_TREASURY_BOND
    33: 'Bond', // OTHER_GOVERNMENT
    34: 'Bond', // US_TREASURY_ZERO_COUPON
    35: 'Bond', // GOVERNMENT_MORTGAGE
    36: 'Bond', // COLLATERALIZED_MORTGAGE_OBLIGATION
    53: 'Bond', // WHEN_AS_AND_IF_ISSUED_BOND
    65: 'Bond', // BANKERS_ACCEPTANCE
    81: 'Bond', // CORPORATE_BOND_NOTE
    82: 'Bond', // CORPORATE_BOND_NOTE
    87: 'Bond', // CORPORATE_BOND_NON_CONVERTIBLE_DOMESTIC
    88: 'Bond', // CORPORATE_BOND_NOTE_CONVERTIBLE_DOMESTIC
    89: 'Bond', // CORPORATE_NOTE_NON_CONVERTIBLE_DOMESTIC
    90: 'Bond', // CORPORATE_UNITS_BONDS
    91: 'Bond', // CORPORATE_UNITS_STOCK
    96: 'Bond', // FEDERAL_AGENCY_BOND
    97: 'Bond', // FEDERAL_AGENCY_PASS_THROUGH
    98: 'Bond', // FEDERAL_AGENCY_REGULAR_BILL
    99: 'Bond', // GOVERNMENT_BOND
    108: 'Bond', // MUNI_DOUBLE_BARRELED_BOND
    109: 'Bond', // MUNI_GENERAL_OBLIGATION
    110: 'Bond', // MUNI_REVENUE_BOND
    111: 'Bond', // MUNICIPAL_BOND_NOTE
    112: 'Bond', // MUNICIPAL_LEASES
    113: 'Bond', // MUNICIPAL_BOND_SERIAL,
    114: 'Bond', // MUNICIPAL_NOTE_SHORT_TERM
    124: 'Bond', // REAL_ESTATE_INVESTMENT_TRUST
    132: 'Bond', // TREASURY_BILL
    133: 'Bond', // TREASURY_BOND
    134: 'Bond', // TREASURY_INFLATION_INDEXED
    135: 'Bond', // TREASURY_NOTE
    136: 'Bond', // TREASURY_STRIPS
    137: 'Bond', // UNIT_INVESTMENT_TRUST
    138: 'Bond', // UNIT_INVESTMENT_TRUST_CLOSED_END_NON_TAX
    139: 'Bond', // UNIT_INVESTMENT_TRUST_CLOSED_END_TAXABLE
    140: 'Bond', // US_TREASURY_FUTURE_BILL
    141: 'Bond', // US_TREASURY_REGULAR_BOND_NOTE
    68: 'CD', // CERTIFICATES_OF_DEPOSIT
    78: 'CD', // CERTIFICATE_OF_DEPOSIT
    71: 'CD', // COMMERCIAL_PAPER
    75: 'CD', // FIXED_INCOME_UNITS,
    84: 'CD', // COMMERCIAL_PAPER_REGULAR_FLAT_DISCOUNT
    85: 'CD', // COMMERCIAL_PAPER_REGULAR_INTEREST_BEARIN
    201: 'CD', // MISCELLANEOUS_FIXED_INCOME
    7: 'Mutual_Fund',
    115: 'Mutual_Fund', // MUTUAL_FUNDS_NO_LOAD_NON_TAXABLE
    116: 'Mutual_Fund', // MUTUAL_FUNDS_NO_LOAD_TAXABLE
    117: 'Mutual_Fund',// MUTUAL_FUNDS_OPEN_END_NON_TAXABLE
    118: 'Mutual_Fund',// MUTUAL_FUNDS_OPEN_END_TAXABLE
  };

  getAssetTypeService (position): AssetType | undefined {
    let assetType: AssetType | undefined;
    if (position.securityType) {
      assetType = this.securityClassToDetailsType[position.securityType];
    }

    if (assetType === 'Money_Market_Fund') {
      if((position.securityName && position.securityName.toLowerCase().includes("vanguard")) && (position.cusip !== "CMA12Q45Q" && position.cusip !== "PBK12Q799")){
        assetType = 'Vanguard_Mutual_Fund';
      }
      else if(isCashPlus(position)){
        assetType = 'Vanguard_Cash_Plus';
      }
      else if(isCashDeposit(position)){
        assetType = 'Vanguard_Cash_Deposit';
      }
      else{
        assetType = 'Mutual_Fund';
      }
    }
    if (!assetType || assetType === 'Mutual_Fund') {
      if (position.securityName && position.securityName.toLowerCase().includes("vanguard")) {
        assetType = 'Vanguard_Mutual_Fund';
      } else {
        assetType = 'Mutual_Fund';
      }
    }
    return assetType;
  }
}
