import './polyfills';

import {bootstrapApplication} from '@angular/platform-browser';
import {AppComponent} from '@app/app.component';
import {importProvidersFrom} from '@angular/core';
import {AppRouteProviders} from '@app/route.providers';
import {HttpProviders} from '@app/http.providers';
import {InitializeProviders} from "@app/init.providers";
import {GraphQLModule} from "@app/graphql.module";


bootstrapApplication(AppComponent, {
  providers: [
    ...HttpProviders,
    ...AppRouteProviders,
    ...InitializeProviders,
    importProvidersFrom(
      GraphQLModule
    ),
  ],
});
