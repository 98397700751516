import { AccountPosition } from '@generated/graphql';

const cashPlusCusip: string = "CMA12Q45Q";
const cashPlusName: string = "VANGUARD CASH PLUS";
const cashDepositCusip: string = "PBK12Q799";
const cashDepositName: string ="VANGUARD CASH DEPOSIT";

export function isCashPlus(accountPosition: Pick<AccountPosition, | 'cusip'| 'securityName'>): boolean {
  return accountPosition.cusip === cashPlusCusip || accountPosition.securityName === cashPlusName;
}

export function isCashDeposit(accountPosition: Pick<AccountPosition, | 'cusip'| 'securityName'>): boolean {
  return accountPosition.cusip === cashDepositCusip || accountPosition.securityName === cashDepositName;
}
