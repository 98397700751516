<ng-container content [bundleName]="'bydToolbar'" #content="bundle">
  <div class="d-flex align-items-left flex-column flex-md-row py-3 py-md-0">

    <div class="date-selector d-flex align-items-top label-calendar-container">
      <div class="d-flex align-items-center label-container">
        <span class="c11n-text-md-bold pe-4 pe-lg-3">{{content['date']}}</span>
      </div>
      <c11n-date-picker
        class="calendar-container"
        aria-label="Date Picker"
        [hintText]="content['hintText'] + (this.maxSelectedDate | date: 'MM/dd/yyyy')"
        [labelText]=""
        [hideLabel]="true"
        [hasError]="showErrorText"
        [errorText]="content['errorText'] + (this.maxSelectedDate | date: 'MM/dd/yyyy')"
      >
        <input c11nDatePickerInput
               type="date"
               id="balance-date"
               name="balance-date"
               [max]="maxSelectedDate"
               value="{{selectedDate}}"
               (change)="onDateChanged($event)"
        >
      </c11n-date-picker>
    </div>

    <div>
      <div class="d-none d-md-block ps-4">
        <c11n-button
          aria-label="Set date"
          [labelText]="'Set date'"
          [variant]="'secondary'"
          (clickEvent)="onButtonClick($event)"
        ></c11n-button>
      </div>

      <div class="d-block d-md-none button-padding pt-3">
        <c11n-button
          aria-label="Set date"
          [labelText]="'Set date'"
          [variant]="'secondary'"
          [size]="'small'"
          (clickEvent)="onButtonClick($event)"
        ></c11n-button>
      </div>
    </div>
  </div>

</ng-container>
