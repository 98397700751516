import { Injectable } from '@angular/core';
import { Observable, Subject, tap } from 'rxjs';
import { content } from '@content/content';
import { LoggerService } from '@vanguard/secure-site-components-lib';
import { map } from 'rxjs/operators';
import { LoggerCode } from '@models/logger';
import { PageUrlEnum } from '@models/page-url.enum';
import { GraphQLService } from '@app/core/services/graphql/graphql.service';
import { ApolloQueryResult } from '@apollo/client/core';
import { AssetType, AssetTypeService } from '@app/core/services/asset-grouping/asset-type.service';
import { PositionGroupingService } from '@app/core/services/asset-grouping/position-grouping.service';
import {
  Account,
  YearEndAccountData,
  GroupedAccounts,
  PartialError,
  ParticipantYearEnds,
  Positions,
  Total,
  YearEndData,
  AccountHeaders,
} from '@models/byDateYearEndTypes';
import { YearEndGQL, YearEndQuery, YearEndQueryVariables } from '@generated/graphql';
import { CommonService } from '@app/core/services/common/common.service';

@Injectable({
  providedIn: 'root',
})
export class YearEndService {
  private yearEnd: Observable<YearEndAccountData>;
  public managed: Account[] = [];
  public selfManaged: Account[] = [];

  constructor(
    private yearEndGQL: YearEndGQL,
    private gqlService: GraphQLService,
    private loggerService: LoggerService,
    private assetTypeService: AssetTypeService,
    private positionGroupingService: PositionGroupingService,
    private commonService: CommonService,
  ) {}

  // Balances compared to year-end functions
  public fetchYearEnd(): Observable<YearEndAccountData> {
    return this.yearEnd || this.getYearEnd();
  }

  private getLastDayOfLastYear = (): Date => {
    const lastYear = new Date().getFullYear() - 1;
    const lastDayOfLastYear = new Date(lastYear, 11, 31);
    return lastDayOfLastYear;
  };

  private getYearEnd(): Observable<YearEndAccountData> {
    this.commonService.loading.next(true);
    const yearEndArgs: YearEndQueryVariables = {
      date: this.commonService.stringifyDate(new Date()),
    };
    return this.gqlService.query<YearEndQuery>(this.yearEndGQL.document, false, yearEndArgs).pipe(
      map((gqlResponse: ApolloQueryResult<YearEndQuery>) => {
        return this.mapBalanceYearEndData(gqlResponse.data);
      }),
      tap(() => this.commonService.loading.next(false)),
    );
  }

  private mapBalanceYearEndData(yearEndResponseData: YearEndQuery): YearEndAccountData {
    try {
      if (!yearEndResponseData) {
        throw new Error('No GQL response data.');
      }
      return this.yearEndData(yearEndResponseData);
    } catch (error: any) {
      return this.buildyearEndIfError(error);
    }
  }

  private yearEndData(yearEndResponseData: YearEndQuery): YearEndAccountData {
    this.commonService.setAdditionalProperties(yearEndResponseData.accountAggregator.accounts);
    let yearEnd = this.getDefaultBalanceYearEnd();
    yearEnd.comparedToYearEnd = this.mapYearEndData(yearEndResponseData);
    return yearEnd;
  }
  private getDetailsType(position): AssetType {
    return this.assetTypeService.getAssetTypeService(position);
  }

  private buildyearEndIfError(error: any): YearEndAccountData {
    const clientInfo = this.getDefaultBalanceYearEnd();
    this.logEvent(error);
    return clientInfo;
  }

  private logEvent(error: any): void {
    this.loggerService.error({
      feature: 'YearendService',
      message: 'Failed to retrieve Yearend info.',
      url: PageUrlEnum.BALANCE_TO_YEAR_END,
      logCode: LoggerCode.YEAREND_DATA_UNAVAIL_ERROR,
      error: error,
    });
  }

  // eslint-disable-next-line complexity
  private accountProductChecker(account: any): boolean {
    let result: boolean =
      account.productType !== 'Vanguard529Plan' &&
      account.productType !== 'Yodlee' &&
      account.productType !== 'OutsideHoldings'
        ? true
        : false;
    if (this.commonService.getGatekeeperToggleValue() === false) {
      result =
        account.productType !== 'MutualFund' &&
        account.productType !== 'MutualFundRetirement' &&
        account.productType !== 'LegacyBrokerage'
          ? true
          : false;
    }
    return result;
  }

  public getDefaultBalanceYearEnd(): YearEndAccountData {
    return {
      comparedToYearEnd: undefined,
    };
  }

  private mapYearEndData(yearEndResponseData: YearEndQuery): YearEndData {
    const mapYearEndHoldingsData = (positions): Positions[] => {
      return positions.map(
        (position): Positions => ({
          sourceAccountId: position.sourceAccountId,
          mergedDate: this.formatDate(position.mergedDate),
          isMerged: position.isMerged,
          balance: position.balance,
          cusip: position.cusip,
          dollarChange: position.dollarChange,
          fundAccountId: position.fundAccountId,
          percentChange: position.percentChange,
          positionId: position.positionId,
          securityName: position.securityName,
          securityType: position.securityType,
          assetType: this.getDetailsType(position),
          settlementFund: position.settlementFund,
          ticker: position.ticker,
          tradeDateBalance: position.tradeDateBalance,
          todayTradeDateBalance: position.todayTradeDateBalance,
          tradeDateDollarChange: position.tradeDateDollarChange,
          tradeDatePercentChange: position.tradeDatePercentChange,
          vanguardFundId: position.vanguardFundId,
          vastAccountNumber: position.vastAccountNumber,
          yearEndBalance: position.yearEndBalance,
          notAvailable: position.notAvailable,
          isClosed: position.isClosed,
          positionType: position.positionType,
        }),
      );
    };
    const setHasUpgradedToVBAAccount = (account: Account): boolean => {
      if (account.upgradedToVBA && account.upgradedToVBAMergedateTime) {
        return (
          this.getLastDayOfLastYear() <=
          new Date(account.upgradedToVBAMergedateTime.toString().slice(0, 10))
        );
      } else {
        return false;
      }
    };
    const mapYearEndAccountsData: Account[] = yearEndResponseData.AccountData.accounts.map(
      (account, i): Account => ({
        accountBeginDate: account.accountBeginDate,
        upgradedToVBA: account.upgradedToVBA,
        upgradedToVBAMergedateTime: account.upgradedToVBAMergedateTime,
        hasUpgradedToVBAAccount: setHasUpgradedToVBAAccount(account as Account),
        accountId: account.accountId,
        accountName: account.accountName,
        balance: account.balance,
        cashManagementAccount: account.cashManagementAccount,
        dollarChange: account.dollarChange,
        managed: account.managed,
        percentChange: account.percentChange,
        planId: account.planId,
        positions: mapYearEndHoldingsData(account.positions),
        groupedPositions: this.positionGroupingService.getPositionsGrouping(
          mapYearEndHoldingsData(account.positions),
        ),
        productType: account.productType,
        serviceAgreementId: account.serviceAgreementId,
        yearEndBalance: account.yearEndBalance,
        marginCode: this.findMarginCode(
          yearEndResponseData.accountAggregator.accounts,
          account.accountId,
        ),
      }),
    );
    const mapYearEndPartialErrors: PartialError[] =
      yearEndResponseData.AccountData.partialErrors.map((partialError) => ({
        code: partialError.code,
        accountId: partialError.accountId,
        description: partialError.description,
      }));

    const mapYearEndParticipantPlans: ParticipantYearEnds[] =
      yearEndResponseData.AccountData.participantYearEnds.map((participant) => ({
        planId: participant.planId,
        planName: participant.planName,
        yearEndAsOfDate: participant.yearEndAsOfDate,
        yearEndBalance: participant.yearEndBalance,
      }));
    return {
      clientPoId: Number(yearEndResponseData.AccountData.clientPoId),
      accounts: mapYearEndAccountsData,
      participantYearEnds: mapYearEndParticipantPlans,
      hasEmployerPlanAccount: yearEndResponseData?.AccountData?.hasEmployerPlan,
      total: {
        totalBalance: yearEndResponseData.AccountData.total.totalBalance,
        totalBalanceManaged: yearEndResponseData.AccountData.total.totalBalanceManaged,
        totalYearEndBalance: yearEndResponseData.AccountData.total.totalYearEndBalance,
        totalDollarChange: yearEndResponseData.AccountData.total.totalDollarChange,
        totalPercentChange: yearEndResponseData.AccountData.total.totalPercentChange,
        totalBalanceSelfManaged: yearEndResponseData.AccountData.total.totalBalanceSelfManaged,
        totalBalanceAsOfDate: yearEndResponseData.AccountData.total.totalBalanceAsOfDate,
      } as Total,
      partialError: mapYearEndPartialErrors,
    };
  }

  public findAccountsByGroups(yearEndData: YearEndAccountData): void {
    this.managed = [];
    this.selfManaged = [];
    yearEndData?.comparedToYearEnd?.accounts.forEach((account) => {
      if (this.accountProductChecker(account) === true) {
        if (account.managed === true) {
          this.managed.push(
            this.getAccountItemById(
              yearEndData.comparedToYearEnd,
              account.accountId,
              account.planId,
            ),
          );
        } else if (account.managed === false) {
          this.selfManaged.push(
            this.getAccountItemById(
              yearEndData.comparedToYearEnd,
              account.accountId,
              account.planId,
            ),
          );
        }
      }
    });
    this.managed = this.managed.filter((item) => item);
    this.selfManaged = this.selfManaged.filter((item) => item);
    this.commonService.setDataAvailability(yearEndData?.comparedToYearEnd?.accounts);
  }

  public getAccountItemById(yearEnd: YearEndData, accountId: string, planId: string): Account {
    if (accountId) {
      return yearEnd.accounts.filter((yearEndAccount) => yearEndAccount.accountId === accountId)[0];
    } else {
      return yearEnd.accounts.filter((yearEndAccount) => yearEndAccount.planId === planId)[0];
    }
  }

  public getGroupedAccounts(): GroupedAccounts {
    return {
      managedAccounts: this.managed,
      selfManagedAccounts: this.selfManaged,
    };
  }

  public getAccountGroupHeaders(yearEndData: YearEndAccountData): AccountHeaders {
    return {
      managed: {
        title: content.bydLayout.accountGroupingHeaders.managedHeader,
        amount: yearEndData?.comparedToYearEnd?.total?.totalBalanceManaged,
      },
      selfManaged: {
        title: content.bydLayout.accountGroupingHeaders.selfManagedHeader,
        amount: yearEndData?.comparedToYearEnd?.total?.totalBalanceSelfManaged,
      },
    };
  }

  public getTotalBalance(yearEndData: YearEndAccountData): Total {
    return yearEndData?.comparedToYearEnd?.total;
  }

  findMarginCode(accounts: Account[], accountId: String): string {
    let matchedAcc = accounts.find((obj) => obj.accountId === accountId);
    return matchedAcc ? matchedAcc.marginCode : '';
  }

  private formatDate(date: string): string {
    if (date !== undefined && date !== null) {
      const parts = date.split('-');
      const year = parts[0];
      const month = parts[1];
      const day = parts[2];
      return `${month}/${day}/${year}`;
    }
    return undefined;
  }
}
