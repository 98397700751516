import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS, ApolloModule} from 'apollo-angular';
import {ApolloClientOptions, ApolloLink, from, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {environment} from "@environments/environment";
import {setContext} from "@apollo/client/link/context";
import {onError} from "@apollo/client/link/error";
import {LoggerCode} from "@models/logger";
import {CookieService} from "ngx-cookie-service";
import {LoggerService} from "@vanguard/secure-site-components-lib";

export function createApollo(
  httpLink: HttpLink,
  cookie: CookieService,
  loggerService: LoggerService
): ApolloClientOptions<any> {

  const errorLink = onError(({graphQLErrors, networkError}) => {
    if (graphQLErrors) {
      graphQLErrors.forEach(({originalError, message}) =>
        loggerService.error({
          feature: 'ApolloService',
          url: environment.GRAPHQL_URI,
          message: message,
          logCode: LoggerCode.APOLLO_CLIENT_ERROR,
          error: originalError || message,
        }),
      );
    }
    if (networkError) {
      loggerService.error({
        feature: 'ApolloService',
        message: networkError.message,
        logCode: LoggerCode.HTTP_CLIENT_ERROR,
        error: networkError,
      });
    }
  });

  const headers = setContext((operation, context) => ({
    headers: {
      Accept: 'application/json',
      'X-XSRF-TOKEN': cookie.get('XSRF-TOKEN'),
    },
  }));

  const apolloLink = ApolloLink.from([
    headers,
    httpLink.create({
      uri: environment.GRAPHQL_URI,
      withCredentials: true,
    }),
  ]);

  return {
    name: 'holdings.frontend',
    link: from([errorLink, apolloLink]),
    cache: new InMemoryCache(),
  };
}

@NgModule({
  exports: [ApolloModule],
  providers: [
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, CookieService, LoggerService],
    },
  ],
})
export class GraphQLModule {
}

