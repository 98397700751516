import {provideRouter, Routes, withInMemoryScrolling, withRouterConfig} from "@angular/router";
import {PageUrlEnum} from "@models/page-url.enum";

const AppRoutes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'by-date',
  },
  {
    path: PageUrlEnum.BALANCE_BY_DATE,
    loadComponent: () =>
      import('./layout/balances-by-date/balances-by-date.component').then(
        (m) => m.BalancesByDateComponent
      ),
  },
  {
    path: PageUrlEnum.BALANCE_TO_YEAR_END,
    loadComponent: () =>
      import('./layout/balances-compared-to-year-end/balances-compared-to-year-end.component').then(
        (m) => m.BalancesComparedToYearEndComponent
      ),
  },
  // {
  //   path: 'content',
  //   loadChildren: () =>
  //     import('./core/routes/content.route').then((m) => m.ContentRoutes),
  // },
];

export const AppRouteProviders = [
  provideRouter(
    AppRoutes,
    withInMemoryScrolling({
      scrollPositionRestoration: 'disabled',
    }),
    withRouterConfig({
      paramsInheritanceStrategy: 'always',
      onSameUrlNavigation: 'reload',
    }),
    // withEnabledBlockingInitialNavigation(),
  )
];
