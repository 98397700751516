<ng-container content [bundleName]="'bydLayout'" #content="bundle">
  <div class="d-flex justify-content-start justify-content-lg-end py-1">
    <div class="d-flex flex-column flex-sm-row" role="group">
      <button [attr.data-testid]="'expand'" c11n-link (click)="expandAll(true)" [attr.aria-label]="isExpanded?' expand all accounts active' : 'expand all accounts inactive'">
        {{content['expandAll']}}
      </button>
      <span class="px-2 d-none d-sm-block">|</span>
      <button [attr.data-testid]="'collapse'" c11n-link (click)="expandAll(false)"   [attr.aria-label]="!isExpanded? 'collapse all accounts active':'collapse all accounts inactive'">
        {{content['collapseAll']}}
      </button>
    </div>
  </div>
</ng-container>
