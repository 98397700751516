import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};



export type Account = {
  __typename?: 'Account';
  accountBeginDate?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  balanceAsOfDateTime?: Maybe<Scalars['String']>;
  balanceByDate?: Maybe<Scalars['Float']>;
  cashManagementAccount?: Maybe<Scalars['Boolean']>;
  dollarChange?: Maybe<Scalars['Float']>;
  managed?: Maybe<Scalars['Boolean']>;
  marginCode?: Maybe<Scalars['String']>;
  percentChange?: Maybe<Scalars['Float']>;
  planId?: Maybe<Scalars['String']>;
  positions?: Maybe<Array<AccountPosition>>;
  productType?: Maybe<Scalars['String']>;
  serviceAgreementId?: Maybe<Scalars['String']>;
  totalBalanceByDate?: Maybe<Scalars['Float']>;
  upgradedToVBA?: Maybe<Scalars['Boolean']>;
  upgradedToVBAMergedateTime?: Maybe<Scalars['String']>;
  yearEndBalance?: Maybe<Scalars['Float']>;
};

export type AccountAggregatorData = {
  __typename?: 'AccountAggregatorData';
  accounts: Array<Account>;
  clientPoId: Scalars['Float'];
  partialErrors?: Maybe<Array<PartialError>>;
  total: Total;
};

export type AccountByDate = {
  __typename?: 'AccountByDate';
  accountId?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  accountType?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  positions?: Maybe<Array<AccountPositionByDate>>;
  productType?: Maybe<Scalars['String']>;
};

export type AccountByDateData = {
  __typename?: 'AccountByDateData';
  accounts: Array<AccountByDate>;
  clientPoId?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<Errors>>;
  participantYearEnds?: Maybe<Array<ParticipantYearEnds>>;
};

export type AccountData = {
  __typename?: 'AccountData';
  accounts?: Maybe<Array<Account>>;
  clientPoId?: Maybe<Scalars['Float']>;
  hasEmployerPlan?: Maybe<Scalars['Boolean']>;
  partialErrors?: Maybe<Array<PartialError>>;
  participantYearEnds?: Maybe<Array<ParticipantYearEnds>>;
  total?: Maybe<Total>;
};

export type AccountPosition = {
  __typename?: 'AccountPosition';
  accruedDividends?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  balanceByDate?: Maybe<Scalars['Float']>;
  cusip?: Maybe<Scalars['String']>;
  dollarChange?: Maybe<Scalars['Float']>;
  fundAccountId?: Maybe<Scalars['String']>;
  isClosed?: Maybe<Scalars['Boolean']>;
  isMerged?: Maybe<Scalars['Boolean']>;
  mergedDate?: Maybe<Scalars['String']>;
  notAvailable?: Maybe<Scalars['Boolean']>;
  percentChange?: Maybe<Scalars['Float']>;
  positionId?: Maybe<Scalars['String']>;
  positionType?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  priceAsOfDateTime?: Maybe<Scalars['String']>;
  securityName?: Maybe<Scalars['String']>;
  securityType?: Maybe<Scalars['String']>;
  settlementFund?: Maybe<Scalars['Boolean']>;
  shareQuantity?: Maybe<Scalars['String']>;
  sourceAccountId?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
  todayTradeDateBalance?: Maybe<Scalars['Float']>;
  totalBalanceByDate?: Maybe<Scalars['Float']>;
  tradeDateBalance?: Maybe<Scalars['Float']>;
  tradeDateDollarChange?: Maybe<Scalars['Float']>;
  tradeDatePercentChange?: Maybe<Scalars['Float']>;
  vanguardFundId?: Maybe<Scalars['String']>;
  vastAccountNumber?: Maybe<Scalars['String']>;
  yearEndBalance?: Maybe<Scalars['Float']>;
};

export type AccountPositionByDate = {
  __typename?: 'AccountPositionByDate';
  balance?: Maybe<Scalars['Float']>;
  positionId?: Maybe<Scalars['String']>;
  positionType?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['String']>;
  priceAsOfDateTime?: Maybe<Scalars['String']>;
  securityName?: Maybe<Scalars['String']>;
  securityType?: Maybe<Scalars['String']>;
  settlementFund?: Maybe<Scalars['Boolean']>;
  shareQuantity?: Maybe<Scalars['String']>;
  ticker?: Maybe<Scalars['String']>;
};

export type ClientInfo = {
  __typename?: 'ClientInfo';
  preferredWebName?: Maybe<Scalars['String']>;
};

export type Distribution = {
  __typename?: 'Distribution';
  accruedDividend?: Maybe<Scalars['Float']>;
  accruedDividendDate?: Maybe<Scalars['String']>;
  positionId?: Maybe<Scalars['String']>;
};

export type DivCapGains = {
  __typename?: 'DivCapGains';
  accountId: Scalars['String'];
  accruedDividends?: Maybe<Array<Distribution>>;
};

export type Errors = {
  __typename?: 'Errors';
  accountId?: Maybe<Scalars['String']>;
  errorType?: Maybe<Scalars['String']>;
};

export type MergeExpandHistory = {
  __typename?: 'MergeExpandHistory';
  destinationAccountId?: Maybe<Scalars['String']>;
  mergeDateTime?: Maybe<Scalars['String']>;
  sourceAccountId?: Maybe<Scalars['String']>;
};

export type MergeExpandHistoryData = {
  __typename?: 'MergeExpandHistoryData';
  mergeExpandHistoryAccounts?: Maybe<Array<MergeExpandHistory>>;
};

export type PartialError = {
  __typename?: 'PartialError';
  accountId?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type ParticipantYearEnds = {
  __typename?: 'ParticipantYearEnds';
  planId?: Maybe<Scalars['String']>;
  planName?: Maybe<Scalars['String']>;
  yearEndAsOfDate?: Maybe<Scalars['String']>;
  yearEndBalance?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  AccountData: AccountData;
  /** Returns Client Info */
  ClientInfo?: Maybe<ClientInfo>;
  /** Returns accrued dividends information */
  DivCapGains: Array<DivCapGains>;
  MergeExpandHistoryData: MergeExpandHistoryData;
  accountAggregator: AccountAggregatorData;
  balanceByDate: AccountByDateData;
  /** Returns last logon */
  lastlogon?: Maybe<LastlogonModel>;
  sayHello: Scalars['String'];
};


export type QueryAccountDataArgs = {
  date: Scalars['String'];
  yearEnd: Scalars['Boolean'];
};


export type QueryDivCapGainsArgs = {
  accountIds: Scalars['String'];
  endDate: Scalars['String'];
  startDate: Scalars['String'];
};


export type QueryBalanceByDateArgs = {
  date: Scalars['String'];
  yearend: Scalars['Boolean'];
};

export type Total = {
  __typename?: 'Total';
  contains403b?: Maybe<Scalars['Boolean']>;
  totalBalance?: Maybe<Scalars['Float']>;
  totalBalanceAsOfDate?: Maybe<Scalars['String']>;
  totalBalanceByDate?: Maybe<Scalars['Float']>;
  totalBalanceManaged?: Maybe<Scalars['Float']>;
  totalBalanceSelfManaged?: Maybe<Scalars['Float']>;
  totalDollarChange?: Maybe<Scalars['Float']>;
  totalPercentChange?: Maybe<Scalars['Float']>;
  totalYearEndBalance?: Maybe<Scalars['Float']>;
};

export type LastlogonModel = {
  __typename?: 'lastlogonModel';
  lastLoginTimestamp?: Maybe<Scalars['String']>;
  lastLoginTimestampWithTimeZone?: Maybe<Scalars['String']>;
};

export type AccountDataQueryVariables = Exact<{ [key: string]: never; }>;


export type AccountDataQuery = (
  { __typename?: 'Query' }
  & { accountAggregator: (
    { __typename?: 'AccountAggregatorData' }
    & { total: (
      { __typename?: 'Total' }
      & Pick<Total, 'totalBalance' | 'totalBalanceAsOfDate'>
    ) }
  ) }
);

export type ByDateQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type ByDateQuery = (
  { __typename?: 'Query' }
  & { AccountData: (
    { __typename?: 'AccountData' }
    & Pick<AccountData, 'clientPoId' | 'hasEmployerPlan'>
    & { accounts?: Maybe<Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'upgradedToVBA' | 'upgradedToVBAMergedateTime' | 'accountBeginDate' | 'accountId' | 'accountName' | 'balance' | 'balanceAsOfDateTime' | 'balanceByDate' | 'cashManagementAccount' | 'managed' | 'planId' | 'productType' | 'serviceAgreementId' | 'totalBalanceByDate'>
      & { positions?: Maybe<Array<(
        { __typename?: 'AccountPosition' }
        & Pick<AccountPosition, 'sourceAccountId' | 'mergedDate' | 'isMerged' | 'accruedDividends' | 'balance' | 'balanceByDate' | 'cusip' | 'fundAccountId' | 'positionId' | 'positionType' | 'price' | 'priceAsOfDateTime' | 'securityName' | 'securityType' | 'settlementFund' | 'shareQuantity' | 'ticker' | 'totalBalanceByDate' | 'tradeDateBalance' | 'todayTradeDateBalance' | 'tradeDateDollarChange' | 'tradeDatePercentChange' | 'vanguardFundId' | 'vastAccountNumber' | 'notAvailable' | 'isClosed'>
      )>> }
    )>>, partialErrors?: Maybe<Array<(
      { __typename?: 'PartialError' }
      & Pick<PartialError, 'accountId' | 'code' | 'description'>
    )>>, total?: Maybe<(
      { __typename?: 'Total' }
      & Pick<Total, 'totalBalance' | 'totalBalanceAsOfDate' | 'totalBalanceByDate' | 'totalBalanceManaged' | 'totalBalanceSelfManaged'>
    )> }
  ), accountAggregator: (
    { __typename?: 'AccountAggregatorData' }
    & { accounts: Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'accountId' | 'productType' | 'accountBeginDate' | 'marginCode'>
    )> }
  ) }
);

export type ClientInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type ClientInfoQuery = (
  { __typename?: 'Query' }
  & { ClientInfo?: Maybe<(
    { __typename?: 'ClientInfo' }
    & Pick<ClientInfo, 'preferredWebName'>
  )>, lastlogon?: Maybe<(
    { __typename?: 'lastlogonModel' }
    & Pick<LastlogonModel, 'lastLoginTimestampWithTimeZone'>
  )>, accountAggregator: (
    { __typename?: 'AccountAggregatorData' }
    & { accounts: Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'cashManagementAccount' | 'productType'>
    )>, total: (
      { __typename?: 'Total' }
      & Pick<Total, 'totalBalance' | 'totalBalanceAsOfDate'>
    ) }
  ) }
);

export type TestQueryVariables = Exact<{ [key: string]: never; }>;


export type TestQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'sayHello'>
);

export type YearEndQueryVariables = Exact<{
  date: Scalars['String'];
}>;


export type YearEndQuery = (
  { __typename?: 'Query' }
  & { AccountData: (
    { __typename?: 'AccountData' }
    & Pick<AccountData, 'clientPoId' | 'hasEmployerPlan'>
    & { accounts?: Maybe<Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'upgradedToVBA' | 'upgradedToVBAMergedateTime' | 'accountBeginDate' | 'accountId' | 'accountName' | 'balance' | 'cashManagementAccount' | 'dollarChange' | 'managed' | 'percentChange' | 'planId' | 'productType' | 'serviceAgreementId' | 'yearEndBalance'>
      & { positions?: Maybe<Array<(
        { __typename?: 'AccountPosition' }
        & Pick<AccountPosition, 'sourceAccountId' | 'mergedDate' | 'isMerged' | 'accruedDividends' | 'balance' | 'cusip' | 'dollarChange' | 'fundAccountId' | 'percentChange' | 'positionId' | 'positionType' | 'securityName' | 'securityType' | 'settlementFund' | 'ticker' | 'tradeDateBalance' | 'todayTradeDateBalance' | 'tradeDateDollarChange' | 'tradeDatePercentChange' | 'vanguardFundId' | 'vastAccountNumber' | 'yearEndBalance' | 'notAvailable' | 'isClosed'>
      )>> }
    )>>, partialErrors?: Maybe<Array<(
      { __typename?: 'PartialError' }
      & Pick<PartialError, 'accountId' | 'code' | 'description'>
    )>>, participantYearEnds?: Maybe<Array<(
      { __typename?: 'ParticipantYearEnds' }
      & Pick<ParticipantYearEnds, 'planId' | 'planName' | 'yearEndAsOfDate' | 'yearEndBalance'>
    )>>, total?: Maybe<(
      { __typename?: 'Total' }
      & Pick<Total, 'totalBalance' | 'totalBalanceManaged' | 'totalBalanceSelfManaged' | 'totalDollarChange' | 'totalPercentChange' | 'totalYearEndBalance' | 'totalBalanceAsOfDate'>
    )> }
  ), accountAggregator: (
    { __typename?: 'AccountAggregatorData' }
    & { accounts: Array<(
      { __typename?: 'Account' }
      & Pick<Account, 'accountId' | 'productType' | 'accountBeginDate' | 'marginCode'>
    )> }
  ) }
);

export const AccountDataDocument = gql`
    query accountData {
  accountAggregator {
    total {
      totalBalance
      totalBalanceAsOfDate
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class AccountDataGQL extends Apollo.Query<AccountDataQuery, AccountDataQueryVariables> {
    document = AccountDataDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ByDateDocument = gql`
    query byDate($date: String!) {
  AccountData(date: $date, yearEnd: false) {
    clientPoId
    hasEmployerPlan
    accounts {
      upgradedToVBA
      upgradedToVBAMergedateTime
      accountBeginDate
      accountId
      accountName
      balance
      balanceAsOfDateTime
      balanceByDate
      cashManagementAccount
      managed
      planId
      productType
      serviceAgreementId
      totalBalanceByDate
      positions {
        sourceAccountId
        mergedDate
        isMerged
        accruedDividends
        balance
        balanceByDate
        cusip
        fundAccountId
        positionId
        positionType
        price
        priceAsOfDateTime
        securityName
        securityType
        settlementFund
        shareQuantity
        ticker
        totalBalanceByDate
        tradeDateBalance
        todayTradeDateBalance
        tradeDateDollarChange
        tradeDatePercentChange
        vanguardFundId
        vastAccountNumber
        notAvailable
        isClosed
      }
    }
    partialErrors {
      accountId
      code
      description
    }
    total {
      totalBalance
      totalBalanceAsOfDate
      totalBalanceByDate
      totalBalanceManaged
      totalBalanceSelfManaged
    }
  }
  accountAggregator {
    accounts {
      accountId
      productType
      accountBeginDate
      marginCode
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ByDateGQL extends Apollo.Query<ByDateQuery, ByDateQueryVariables> {
    document = ByDateDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const ClientInfoDocument = gql`
    query clientInfo {
  ClientInfo {
    preferredWebName
  }
  lastlogon {
    lastLoginTimestampWithTimeZone
  }
  accountAggregator {
    accounts {
      cashManagementAccount
      productType
    }
    total {
      totalBalance
      totalBalanceAsOfDate
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class ClientInfoGQL extends Apollo.Query<ClientInfoQuery, ClientInfoQueryVariables> {
    document = ClientInfoDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const TestDocument = gql`
    query test {
  sayHello
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class TestGQL extends Apollo.Query<TestQuery, TestQueryVariables> {
    document = TestDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const YearEndDocument = gql`
    query yearEnd($date: String!) {
  AccountData(date: $date, yearEnd: true) {
    clientPoId
    hasEmployerPlan
    accounts {
      upgradedToVBA
      upgradedToVBAMergedateTime
      accountBeginDate
      accountId
      accountName
      balance
      cashManagementAccount
      dollarChange
      managed
      percentChange
      planId
      productType
      serviceAgreementId
      yearEndBalance
      positions {
        sourceAccountId
        mergedDate
        isMerged
        accruedDividends
        balance
        cusip
        dollarChange
        fundAccountId
        percentChange
        positionId
        positionType
        securityName
        securityType
        settlementFund
        ticker
        tradeDateBalance
        todayTradeDateBalance
        tradeDateDollarChange
        tradeDatePercentChange
        vanguardFundId
        vastAccountNumber
        yearEndBalance
        notAvailable
        isClosed
      }
    }
    partialErrors {
      accountId
      code
      description
    }
    participantYearEnds {
      planId
      planName
      yearEndAsOfDate
      yearEndBalance
    }
    total {
      totalBalance
      totalBalanceManaged
      totalBalanceSelfManaged
      totalDollarChange
      totalPercentChange
      totalYearEndBalance
      totalYearEndBalance
      totalBalanceAsOfDate
    }
  }
  accountAggregator {
    accounts {
      accountId
      productType
      accountBeginDate
      marginCode
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class YearEndGQL extends Apollo.Query<YearEndQuery, YearEndQueryVariables> {
    document = YearEndDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }