import {
    AdobeLaunchService, GATEKEEPER_ENDPOINT, LOGGER_CONFIG, WindowRef,
    Environment as ComponentLibEnvironment,
    ENVIRONMENT_CONFIG as COMPONENT_LIB_ENVIRONMENT_CONFIG
} from "@vanguard/secure-site-components-lib";
import {CookieService} from "ngx-cookie-service";
import {Meta} from "@angular/platform-browser";
import {environment} from "@environments/environment";
import {HttpClient} from "@angular/common/http";
import {APP_INITIALIZER} from "@angular/core";
import {AdobeLaunchEnvironment} from "@vanguard/secure-site-components-lib/lib/models/adobe-launch.model";

function initApp(http: HttpClient, adobeLaunch: AdobeLaunchService, meta: Meta, windowRef: WindowRef): Promise<void> {
    const $window: Window = windowRef.nativeWindow;
    return http
        .get(`${environment.CONSUMER_API}/adobe`, { withCredentials: true })
        .toPromise()
        .then((data: any) => {
            const spoid = data.sPOID;
            meta.updateTag({ name: 'DCSext.poid', content: data.poid });
            meta.updateTag({ name: 'WT.seg_6', content: data.crewFlag });
            $window.spoid = spoid; // Used for Medallia
            windowRef.nativeWindow.randomNum = Math.floor(Math.random() * 4) + 1; // Used for Medallia
            adobeLaunch.initialize({ pageTemplate: 'Holdings' }, environment.deployment as AdobeLaunchEnvironment);
            $window.document.body.hidden = false;
        })
        .catch((ignore) => {
          //This will be commented out because since adobe doesnt exist it will redirect to log on.
            $window.document.location.href = environment.LOGON_URL;
            return Promise.reject(); // Ensure that app initialization is aborted since we're going to the (external) logon page.
        });
}

export const InitializeProviders = [
    WindowRef,
    AdobeLaunchService,
    CookieService,
    Meta,
    {
        provide: GATEKEEPER_ENDPOINT,
        useValue: environment.GATEKEEPER_URI,
    },
    {
        provide: LOGGER_CONFIG,
        useValue: { endpoint: environment.LOGGER_URI, app: 'balance-year-date.frontend' },
    },
    {
        provide: COMPONENT_LIB_ENVIRONMENT_CONFIG,
        useValue: {
            isProd: environment.production,
            isPerf: environment.performance,
            isInternal: environment.internal,
            appName: 'balance-year-date.frontend',
        } as ComponentLibEnvironment,
    },
    {
        provide: APP_INITIALIZER,
        useFactory: initApp,
        malty: true,
        deps: [HttpClient, AdobeLaunchService, Meta, WindowRef],
    },
];
