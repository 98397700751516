import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { Observable, of } from 'rxjs';
import { catchError, shareReplay, switchMap } from 'rxjs/operators';

export enum FeatureId {
  TA_ACCOUNTS_VISIBILITY = 'ta-accounts-visibility',
}

@Injectable({
  providedIn: 'root',
})
export class GatekeeperService {
  private readonly url = `${environment['GATEKEEPER_URI']}`;

  private flagsMap: Partial<Record<FeatureId, Observable<boolean>>> = {};

  constructor(private http: HttpClient) {}

  public isFeatureSwitchedOn(featureId: FeatureId): Observable<boolean> {
    if (!this.flagsMap[featureId]) {
      this.flagsMap[featureId] = this.getFeatureObservable(featureId);
    }
    return this.flagsMap[featureId]!;
  }

  private getFeatureObservable(featureId: FeatureId): Observable<boolean> {
    return this.http
      .get<boolean>(`${this.url}/${featureId}`, {
        withCredentials: true,
      })
      .pipe(
        // default GK value to `false`
        catchError(() => of(false)),
        // map to simple observable, so we do not store entire XHR in memory
        switchMap((result: boolean) => of(result)),
        // turn into ReplaySubject, so we can reuse response
        shareReplay(),
      );
  }
}
